
import { createContext, useContext } from 'react'

const defaultValue = {
  refreshTab: () => { },
  closeTab: () => { },
  closeOtherTab: () => { },
}


export const KeepAliveTabContext = createContext(defaultValue);

export const useKeepAliveContext = () => {
    return useContext(KeepAliveTabContext);
}