import React from 'react';
import { api } from "@/redux/api";
import { useSafeState } from "ahooks";
import { Empty, message, Upload } from "antd";
import { useEffect } from "react";

export const getFileExtension = (filename) => {
  return (filename || '').split('.').pop().toLowerCase();
}

export const getPathsByCurrentTree = (currentTreeKey) => {
  let arr = [];
  let cur = currentTreeKey;

  while (cur) {
    arr.unshift(cur);
    cur = cur?.parentFolder;
  }

  return arr
}

const FOLDER_KEY = 'folder-';
const FILE_KEY = 'file-';

export const formatFilesToTreeData = (files, folders, id, props) => {

  if (props.tableName === 'All') {
    return formatFilesToTreeDataWithGlobal(files, folders, id, props)
  }

  const cloneFiles = [...files];
  const cloneFolders = [...folders];
  const root = {
    isRoot: true,
    isFolder: true,
    parentFolder: null,
    isSystem: true,
    key: 'root',
    title: 'root',
    children: [],
    nodes: [],
    context: {
      table_name: props.tableName,
      equipmentId: props.equipmentId,
      record_id: props.recordId,
      canCreate: true,
      canUpload: true
    }
  }

  const FileMapping = new Map();

  for (const folder of cloneFolders) {
    const key = FOLDER_KEY + folder.id;
    folder.key = key;
    folder.isFolder = true;
    folder.title = folder.name;
    folder.children = [];
    folder.nodes = [];
    FileMapping.set(key, folder)
  }

  for (const file of cloneFiles) {
    const key = FILE_KEY + file.id;
    file.key = key;
    file.isFolder = false;
    file.title = file.name;
    FileMapping.set(key, file)
  }


  for (const file of [...cloneFolders, ...cloneFiles]) {
    if (file.parent === null || file.folder === null) {
      if (file.isFolder) {
        root.children.push(file)
      }

      root.nodes.push(file);
      file.parentFolder = root;

    } else {

      const parentId = file.isFolder ? file.parent : file.folder;

      const key = FOLDER_KEY + parentId;
      if (FileMapping.has(key)) {
        const parent = FileMapping.get(key);
        if (file.isFolder) {
          parent.children.push(file);
        }
        parent.nodes.push(file);
        file.parentFolder = parent;
      }
    }
  }
  return [
    root,
    id ? cloneFolders.find(item => item.id === id) : root,
    {
      files: cloneFiles,
      folders: cloneFolders
    }
  ]
}

export const formatFilesToTreeDataWithGlobal = (files, folders, id, props) => {
  const cloneFiles = [...files];
  const cloneFolders = [...folders];

  const equimentFolderMappings = {};
  const workoderFolderMappings = {};
  const funcionalFolderMappings = {};
  const workoderParentFolderMappings = {};
  const funcionalParentFolderMappings = {};

  const root = {
    isRoot: true,
    isFolder: true,
    parentFolder: null,
    isSystem: true,
    key: 'root',
    id: 'root',
    title: 'root',
    children: [],
    nodes: [],
    context: {
      table_name: props.tableName,
      equipmentId: props.equipmentId,
      record_id: props.recordId,
      canCreate: true,
      canUpload: true
    }
  }

  const FileMapping = new Map();


  for (const folder of cloneFolders) {
    const key = FOLDER_KEY + folder.id;
    folder.key = key;
    folder.isFolder = true;
    folder.title = folder.name;
    folder.children = [];
    folder.nodes = [];
    FileMapping.set(key, folder)
  }

  for (const file of cloneFiles) {
    const key = FILE_KEY + file.id;
    file.key = key;
    file.isFolder = false;
    file.title = file.name;
    FileMapping.set(key, file)
  }

  for (const file of [...cloneFolders, ...cloneFiles]) {

    if (file.table_name === 'equipment' && !file.parent && file.equipment_info) {
      makeEquipmentFolder(file.equipment_info, {
        table_name: file.table_name,
        equipmentId: file.equipment_info?.id,
        canCreate: true,
        canUpload: true
      })
    }

    if (file.table_name === 'workorder' && !file.parent && file.equipment_info) {
      makeWorkOrderParentFolder(file.equipment_info);
      makeWorkOrderFolder(file.equipment_info, file.record_id, {
        table_name: file.table_name,
        equipmentId: file.equipment_info?.id,
        record_id: file.record_id,
        canCreate: true,
        canUpload: true
      })
    }

    if (file.table_name === 'functiontesting' && !file.parent && file.equipment_info) {
      makeFunctionalTestingParentFolder(file.equipment_info);
      makeFunctionalTestingFolder(file.equipment_info, file.record_id, {
        table_name: file.table_name,
        equipmentId: file.equipment_info?.id,
        record_id: file.record_id,
        canCreate: true,
        canUpload: true
      })
    }
  }

  const systemFolders = makeSystemRelationShip(root, equimentFolderMappings, workoderFolderMappings, funcionalFolderMappings);

  for (const file of [...cloneFolders, ...cloneFiles]) {
    if (file.parent === null || file.folder === null) {

      let currentParent = root;
      if (file.table_name === 'equipment' && file.equipment_info) {
        currentParent = equimentFolderMappings[file.record_id];
      } else if (file.table_name === 'workorder' && file.equipment_info) {
        currentParent = workoderFolderMappings[file.equipment_info?.id][file.record_id];


      } else if (file.table_name === 'functiontesting' && file.equipment_info) {
        currentParent = funcionalFolderMappings[file.equipment_info?.id][file.record_id];
      }

      if (file.isFolder) {
        currentParent.children.push(file)
      }

      currentParent.nodes.push(file);
      file.parentFolder = currentParent;


    } else {

      const parentId = file.isFolder ? file.parent : file.folder;

      const key = FOLDER_KEY + parentId;
      if (FileMapping.has(key)) {
        const parent = FileMapping.get(key);
        if (file.isFolder) {
          parent.children.push(file);
        }
        parent.nodes.push(file);
        file.parentFolder = parent;
      }
    }
  }

  const mergedFolders = [...cloneFolders, ...systemFolders]

  return [
    root,
    id ? mergedFolders.find(item => item.id === id) : root,
    {
      files: cloneFiles,
      folders: mergedFolders
    }
  ]


  function makeEquipmentFolder(equipment) {
    if (!equipment) {
      return null
    }
    const { id: equipmentId, name: equipmentName } = equipment;

    if (!equimentFolderMappings[equipmentId]) {
      equimentFolderMappings[equipmentId] = {
        isFolder: true,
        key: 'equipment-' + equipmentId,
        key: 'equipment-' + equipmentId,
        isSystem: true,
        title: equipmentName + '-' + equipmentId,
        name: equipmentName + '-' + equipmentId,
        children: [],
        nodes: [],
        context: {
          table_name: 'equipment',
          equipmentId,
          record_id: equipmentId,
          canCreate: true,
          canUpload: true
        }
      }
    }

    return equimentFolderMappings[equipmentId]
  }

  function makeWorkOrderParentFolder(equipment) {
    const { id: equipmentId } = equipment;
    if (!workoderParentFolderMappings[equipmentId]) {
      workoderParentFolderMappings[equipmentId] = {
        isFolder: true,
        key: 'workorderparent-' + equipmentId,
        id: 'workorderparent-' + equipmentId,
        isSystem: true,
        title: 'Workoder',
        name: 'Workoder',
        children: [],
        nodes: [],
        context: {
          canCreate: false,
          canUpload: false
        }
      }
    }
  }

  function makeFunctionalTestingParentFolder(equipment) {
    const { id: equipmentId } = equipment;
    if (!funcionalParentFolderMappings[equipmentId]) {
      funcionalParentFolderMappings[equipmentId] = {
        isFolder: true,
        key: 'functionaltestingparent-' + equipmentId,
        id: 'functionaltestingparent-' + equipmentId,
        isSystem: true,
        title: 'Functional Testing',
        name: 'Functional Testing',
        children: [],
        nodes: [],
        context: {
          canCreate: false,
          canUpload: false
        }
      }
    }
  }


  function makeWorkOrderFolder(equipment, record_id, context) {
    makeEquipmentFolder(equipment);
    const { id: equipmentId } = equipment;
    if (!workoderFolderMappings[equipmentId]) {
      workoderFolderMappings[equipmentId] = {}
    }
    if (!workoderFolderMappings[equipmentId][record_id]) {
      workoderFolderMappings[equipmentId][record_id] = {}
    }
    workoderFolderMappings[equipmentId][record_id] = {
      isFolder: true,
      key: 'workorder-' + record_id,
      id: 'workorder-' + record_id,
      isSystem: true,
      title: 'workorder-' + record_id,
      name: 'workorder-' + record_id,
      children: [],
      nodes: [],
      context: {
        table_name: 'workorder',
        equipmentId,
        record_id,
        canCreate: true,
        canUpload: true
      }
    }
  }

  function makeFunctionalTestingFolder(equipment, record_id) {
    makeEquipmentFolder(equipment);
    const { id: equipmentId } = equipment;
    if (!funcionalFolderMappings[equipmentId]) {
      funcionalFolderMappings[equipmentId] = {}
    }
    if (!funcionalFolderMappings[equipmentId][record_id]) {
      funcionalFolderMappings[equipmentId][record_id] = {}
    }
    funcionalFolderMappings[equipmentId][record_id] = {
      isFolder: true,
      key: 'functional-' + record_id,
      id: 'functional-' + record_id,
      isSystem: true,
      title: record_id,
      name: record_id,
      children: [],
      nodes: [],
      context: {
        table_name: 'functiontesting',
        equipmentId,
        record_id,
        canCreate: true,
        canUpload: true
      }
    }
  }

  function makeSystemRelationShip(root, equimentFolderMappings, workoderFolderMappings, funcionalFolderMappings) {

    const systemFolders = []

    const systemFolder = {
      isFolder: true,
      key: 'system',
      id: 'system',
      title: 'System',
      name: 'System',
      isSystem: true,
      children: [],
      nodes: [],
      context: {
        canCreate: false,
        canUpload: true
      }
    }

    const equipmentRootFolder = {
      isFolder: true,
      key: 'equipment',
      id: 'equipment',
      title: 'Equipment',
      name: 'Equipment',
      isSystem: true,
      children: [],
      nodes: [],
      context: {
        canCreate: false,
        canUpload: false
      }
    }

    root.children.push(systemFolder);
    root.nodes.push(systemFolder);
    systemFolder.parentFolder = root;

    systemFolder.children.push(equipmentRootFolder);
    systemFolder.nodes.push(equipmentRootFolder);
    equipmentRootFolder.parentFolder = systemFolder;

    systemFolders.push(root, systemFolder, equipmentRootFolder)

    for (const equipmentId in equimentFolderMappings) {
      const currentFolder = equimentFolderMappings[equipmentId];
      systemFolders.push(currentFolder)
      equipmentRootFolder.children.push(currentFolder);
      equipmentRootFolder.nodes.push(currentFolder);
      currentFolder.parentFolder = equipmentRootFolder;
    }

    for (const equipmentId in workoderParentFolderMappings) {
      const parentFolder = equimentFolderMappings[equipmentId];
      const childFolder = workoderParentFolderMappings[equipmentId];
      systemFolders.push(childFolder)
      parentFolder.children.push(childFolder);
      parentFolder.nodes.push(childFolder);
      childFolder.parentFolder = parentFolder;
    }

    for (const equipmentId in funcionalParentFolderMappings) {
      const parentFolder = equimentFolderMappings[equipmentId];
      const childFolder = funcionalParentFolderMappings[equipmentId];
      systemFolders.push(childFolder)
      parentFolder.children.push(childFolder);
      parentFolder.nodes.push(childFolder);
      childFolder.parentFolder = parentFolder;
    }


    for (const equipmentId in workoderFolderMappings) {
      for (const recordId in workoderFolderMappings[equipmentId]) {
        const childFolder = workoderFolderMappings[equipmentId][recordId];
        systemFolders.push(childFolder)
        const parentFolder = workoderParentFolderMappings[equipmentId];
        parentFolder.children.push(childFolder);
        parentFolder.nodes.push(childFolder);
        childFolder.parentFolder = parentFolder;
      }
    }

    for (const equipmentId in funcionalFolderMappings) {
      for (const recordId in funcionalFolderMappings[equipmentId]) {
        const childFolder = funcionalFolderMappings[equipmentId][recordId];
        systemFolders.push(childFolder)
        const parentFolder = funcionalParentFolderMappings[equipmentId];
        parentFolder.children.push(childFolder);
        parentFolder.nodes.push(childFolder);
        childFolder.parentFolder = parentFolder;
      }
    }
    return systemFolders
  }
}



export const filterFolders = (nodes) => {
  return nodes.filter(node => node.isFolder).map(node => ({
    ...node,
    children: node.children ? filterFolders(node.children) : undefined,
    nodes: node.children ?? []
  }));
};

export const getSafeUrl = (url = '') => {
  if (url.startsWith('https://facilitykpiclientfile.s3.us-east-2.amazonaws.com/facilitykpiclientfile')) {
    return url.slice(79)
  }
  return url
}

export const usePreviewUrl = (currentOpenFile) => {
  const [previewUrl, setPreviewUrl] = useSafeState();
  const [isFileViewLoading, setIsFileViewLoading] = useSafeState(false)

  const run = async () => {
    setIsFileViewLoading(true)
    let generate_url = await api.generate_url(getSafeUrl(currentOpenFile.image), true).then(
      response => {
        let dataSource = response.data.url
        return dataSource
      }
    ).catch(error => (
      error
    )).finally(() => {
      setIsFileViewLoading(false)
    });
    setPreviewUrl(generate_url)
  }

  useEffect(() => {
    setPreviewUrl('')
    if (currentOpenFile?.image) {
      run()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpenFile])

  return [currentOpenFile?.image ? previewUrl : '', isFileViewLoading];
}
const regex = /\/[^\/?#]+\.(\w+)(?=[?#]|$)/i

const getExtensionByUrl = (url = '') => {
  return url.match(regex)?.[1] ?? ''
}

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
export const isImage = (fileName = '') => {
  const extension = getExtensionByUrl(fileName);

  if (imageExtensions.includes(extension)) {
    return true;
  }
  return false
}

const docExtensions = [
  "doc",    // Word 97-2003 文档
  "docx",   // Microsoft Word 文档
  "ppt",    // PowerPoint 演示文稿
  "pptx",   // Microsoft PowerPoint 演示文稿
  "xls",    // Excel 97-2003 工作簿
  "xlsx"    // Microsoft Excel 工作簿
];

export const isDoc = (fileName = '') => {

  const extension = getExtensionByUrl(fileName);
  if (docExtensions.includes(extension)) {
    return true;
  }
  return false
}

const mediaExtensions = ['mp4', 'mov', 'avi', 'flv', 'rmvb', 'wmv']

export const isMedia = (fileName = '') => {

  const extension = getExtensionByUrl(fileName);

  if (mediaExtensions.includes(extension)) {
    return true;
  }
  return false
}

const audioExtensions = ['mp3', 'wav', 'flac', 'aac', 'ogg', 'wma'];
export const isAudio = (fileName = '') => {
  const extension = getExtensionByUrl(fileName);

  if (audioExtensions.includes(extension)) {
    return true;
  }
  return false
}


const supportIframeExtensions = ['pdf', 'text', 'json', 'html', 'txt'];

export const isSupportIframe = (fileName = '') => {
  const extension = getExtensionByUrl(fileName);

  if (supportIframeExtensions.includes(extension)) {
    return true;
  }
  return false
}

export const tablelocale = (isSearch) => ({
  emptyText: (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={isSearch ? 'No files found.' : 'This folder is empty.'}
    />
  )
});

const suportOpenAppFiles = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx', 'pdf'];

export const canOpenInApp = (record) => {
  if (record.isFolder) {
    return false
  }
  return suportOpenAppFiles.includes(record.name.split('.').pop())
}

// Copy Link
export const copyLink = async (record) => {
  api.generate_url(getSafeUrl(record.image), true).then(
    response => {
      let url = response.data.url
      navigator.clipboard.writeText(url);
      message.success('Link copied to clipboard');
    }
  )
}

export const handleDownload = async (record) => {

  const { image: downloadUrl, name } = record;

  const generate_url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
    response => {
      const url = response.data.url;
      return url
    })

  const link = document.createElement('a');
  link.href = generate_url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link)
}


export const openInBrowser = async (record) => {
  const { image: downloadUrl } = record;

  const generate_url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
    response => {
      const url = response.data.url;
      return url
    })

  const googleDocsUrl = 'https://docs.google.com/viewer?url=' + encodeURIComponent(generate_url) + '&embedded=true';
  window.open(googleDocsUrl, '_blank');
}

export const openInApp = async (record) => {
  const { image: downloadUrl } = record;
  const url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
    response => {
      const url = response.data.url;
      return url
    })
    openAppByUrl(url)
}

export const openAppByUrl = async (url) => {
  const aEl = document.createElement('a');
  switch (getExtensionByUrl(url)) {
    case 'xlsx':
    case 'xls':
      aEl.href = `ms-excel:ofv|u|${url}`
      break;
    case 'pptx':
    case 'ppt':
      aEl.href = `ms-powerpoint:ofv|u|${url}`
      break;
    case 'docx':
    case 'doc':
      aEl.href = `ms-word:ofv|u|${url}`
      break;
    case 'pdf':
      aEl.href = `ms-pdf:ofv|u|${url}`
      break;
    default:
      return
  }
  document.body.appendChild(aEl);
  aEl.click();
  document.body.removeChild(aEl);
}

export const beforeUpload = (file) => {
  const isLessThan20M = file.size / 1024 / 1024 < 20;
  if (!isLessThan20M) {
    message.error('File must be less than 20MB');
    return Upload.LIST_IGNORE;
  }
  return false;
}


export const allowedFileTypes = [
  ...imageExtensions,
  ...docExtensions,
  ...mediaExtensions,
  ...audioExtensions,
  ...supportIframeExtensions
]