import { useSelector } from 'react-redux';
import React, { useMemo, useRef } from "react";
import { Form, Radio, Modal } from "antd";
import { useRole } from "@/hooks/use-role";
import { convertToTreeStructure, getCompanyId } from "../utils";

const AccountManagerAllowKeys = [2.2, 3, 4];
const CompanyAdminAllowKeys = [4];
const options = [
    { value: 1, label: 'Super User' },
    { value: 2.1, label: 'Account Manager' },
    { value: 2.2, label: 'Portfolio Manager' },
    { value: 3, label: 'Company Admin' },
    { value: 4, label: 'Standard User' },
];

export const UserRoleFormItem = ({ disabled, onChange: propsOnChange }) => {
    const allFacilities = useSelector(state => state.Facility.data);
    const companyTreeOptions = useMemo(() => convertToTreeStructure(allFacilities), [allFacilities]);
    const RoleKey = useRole();
    const isComfirmModalOpen = useRef(false);
    const form = Form.useFormInstance();
    const role = Form.useWatch('role');
    
    const companyValue = Form.useWatch('company');
    const company = companyValue?.value ?? companyValue;

    const [modal, contextHolder] = Modal.useModal();

    /**
     * 1.0 - Super User
     * Super User可以assign所有的角色
     * 2.1 - Account Manager
     * Account Manager可以assign给Account Manager, Company Admin, Standard User
     * 2.2 - Portfolio Manager
     * Portfolio Manager 没有任何用户assign权限
     * 3.0 - Company Admin
     * Company Admin可以assign Standard User
     * 4.0 - Standard User
     * Standard User没有任何用户assign权限
     */
    const permissionFilterOptions = useMemo(() => {
        if (RoleKey === 1) {
            return options
        } else if (RoleKey === 2.1) {
            return options.filter(option => AccountManagerAllowKeys.includes(option.value));
        } else if (RoleKey === 3) {
            return options.filter(option => CompanyAdminAllowKeys.includes(option.value));
        }
        return []
    }, [RoleKey]);


    const setRole = (role) => {
        let facilities = [];
        let companies = [];
        if (role === 1) {
            facilities = allFacilities.map(facility => facility.record.id);
            companies = companyTreeOptions.map(company => getCompanyId(company.value));
        } else if (role === 3) {
            facilities = allFacilities.filter(facility => facility.record.company_id === company).map(facility => facility.record.id);
            companies = [company];
        }

        form.setFieldsValue({ role, user: facilities, companies, profile: null });
    }

    const onChange = (e) => {

        if (propsOnChange) {
            propsOnChange(e)
        }
        const targetValue = e.target.value;
        setRole(targetValue);
    }

    return <>
        <Radio.Group
            value={role}
            onChange={onChange}
            disabled={disabled}
        >
            {permissionFilterOptions.map(option => (
                <div style={{ cursor: disabled ? '' : 'pointer', display: 'inline-block' }} onClick={() => {
                    if (disabled) {
                        return
                    }
                    if (option.value === role) {
                        return
                    }
                    if (isComfirmModalOpen.current) {
                        return
                    }
                    isComfirmModalOpen.current = true;
                    modal.confirm({
                        title: 'Warning!',
                        content: <p>Are you sure you want to change role?</p>,
                        onOk: () => {
                            const element = document.getElementById(`${option.value}-role-picker`);
                            element.style.pointerEvents = 'auto';
                            element.click();
                            element.style.pointerEvents = 'none';
                            isComfirmModalOpen.current = false;
                        },
                        onCancel: () => {
                            isComfirmModalOpen.current = false;
                        }
                    })

                }}>
                    <Radio id={`${option.value}-role-picker`} style={{ pointerEvents: 'none' }} key={option.value} value={option.value}>{option.label}</Radio>
                </div>
            ))}
        </Radio.Group>
        {contextHolder}
    </>
}