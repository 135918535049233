import React, { useState } from "react";
import { Row, Col, Select, DatePicker, Form, Spin } from "antd";
import { NormalizedWeatherCharts } from "./components/NormalizedWeatherCharts";
import { ConsumptionAndCostsCharts } from "./components/ConsumptionAndCostsCharts";
import { KeyStatistics } from "./components/KeyStatistics";
import { GaugeCharts } from "./components/GaugeCharts";
import { PieCharts } from "./components/PieCharts";
import { EUIRollingkbtuSqftCharts } from "./components/EUIRollingkbtuSqftCharts";
import { EUIRollingCostsCharts } from "./components/EUIRollingCostsCharts";
import moment from "moment";
import useDateRange from "@/hooks/use-date-range";
import { safemomentmin, safemomentmax } from "./utils";

const FormLabel = ({ children }) => {
    return <span style={{ fontSize: 11, fontWeight: 550, color: '#6A74A5', marginRight: 8 }}>{children}</span>
}

const borderStyle = { borderRadius: 10, border: '1px solid #f0f0f0', background: '#fff' }
const gutter = 8;
const colStyle = {
    xs: 24,
    lg: 12,
    xxl: 8
}
const NewSummary = () => {

    const dateRange = useDateRange();
    const [period, setPeriod] = useState('12')
    const [endingMonth, setEndingMonth] = useState(() => safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max)))
    const [rangeMonth, setRangeMonth] = useState(() => {
        const max = safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))
        return [moment().subtract(11, 'month'), max]
    })
    const [updateKey, setUpdateKey] = useState(0)



    let resultPeriod = period
    let resultEndingMonth = moment(endingMonth).format('YYYY-MM')
    let resultDateRange = rangeMonth

    if (period === 'custom') {
        resultPeriod = rangeMonth[1].diff(rangeMonth[0], 'month') + 1;
        resultEndingMonth = rangeMonth[1].format('YYYY-MM');
    } else if (period === 'YTD') {
        resultPeriod = moment().diff(moment().startOf('year'), 'month') + 1;
        resultEndingMonth = moment().format('YYYY-MM');
    } else if (period === 'All') {
        const range = [safemomentmin(moment(dateRange.date_range_elec_min), moment(dateRange.date_range_gas_min)), safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))]
        resultPeriod = range[1].diff(range[0], 'month') + 1;
        resultEndingMonth = range[1].format('YYYY-MM');
        resultDateRange = range;
    }

    return (
        <Row gutter={[gutter, gutter]} style={{ margin: '0 8px' }} key={updateKey}>
            <Col span={24}>
                {/* <FormLabel>Range:</FormLabel> */}
                <Select value={period} onChange={setPeriod} style={{ width: 140, marginRight: 12, userSelect: 'none' }} >
                    <Select.Option value="custom">Custom</Select.Option>
                    <Select.Option value="1">1 Month</Select.Option>
                    <Select.Option value="3">3 Month</Select.Option>
                    <Select.Option value='6'>6 Month</Select.Option>
                    <Select.Option value="YTD">YTD</Select.Option>
                    <Select.Option value="12">1 Year</Select.Option>
                    <Select.Option value="24">2 Year</Select.Option>
                    <Select.Option value="36">3 Year</Select.Option>
                    <Select.Option value="60">5 Year</Select.Option>
                    <Select.Option value="All">All</Select.Option>
                </Select>
                {
                    period === 'custom' || period === 'All' ? <>
                        <FormLabel>Range Month:</FormLabel>
                        <DatePicker.RangePicker disabledDate={(current) => {
                            const max = safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))
                            const min = safemomentmin(moment(dateRange.date_range_elec_min), moment(dateRange.date_range_gas_min))
                            return current.isAfter(max, 'month') || current.isBefore(min, 'month')
                        }} disabled={period === 'All'} value={resultDateRange} picker="month" style={{ width: 240 }} allowClear={false} onChange={(value) => {
                            setRangeMonth(value)
                        }} />
                    </> : (period !== 'YTD' && <>
                        <FormLabel>Ending Month:</FormLabel>
                        <DatePicker value={moment(resultEndingMonth)} onChange={(value) => {
                            setEndingMonth(value.format('YYYY-MM'))
                        }} picker="month" disabledDate={(current) => {
                            const max = safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))
                            const min = safemomentmin(moment(dateRange.date_range_elec_min), moment(dateRange.date_range_gas_min))
                            return current.isAfter(max, 'month') || current.isBefore(min, 'month')
                        }} style={{ width: 140 }} />
                    </>)
                }

                <div className='reload-refresh-btn' onClick={() => setUpdateKey(updateKey + 1)}>
                </div>
            </Col>
            <Col {...colStyle}>
                <Row gutter={[0, gutter]}>
                    <Col span={24} style={{ aspectRatio: '2 / 1', ...borderStyle }}>
                        <ConsumptionAndCostsCharts type='electricity' period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                    <Col span={24} style={{ aspectRatio: '2 / 1', ...borderStyle }}>
                        <ConsumptionAndCostsCharts type='gas' period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                    <Col span={24} style={{ aspectRatio: '2 / 1', ...borderStyle }}>
                        <EUIRollingCostsCharts period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                </Row>
            </Col>
            <Col {...colStyle}>
                <Row gutter={[0, gutter]}>
                    <Col span={24} style={{ aspectRatio: '2 / 1', ...borderStyle }}>
                        <NormalizedWeatherCharts type='electricity' period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                    <Col span={24} style={{ aspectRatio: '2 / 1', ...borderStyle }}>
                        <NormalizedWeatherCharts type='gas' period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                    <Col span={24} style={{ aspectRatio: '2 / 1', ...borderStyle }}>
                        <EUIRollingkbtuSqftCharts period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                </Row>
            </Col>

            <Col {...colStyle}>
                <Row gutter={[0, gutter]}>
                    <Col span={24} style={{ ...borderStyle }}>
                        <KeyStatistics period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                    <Col span={12} style={{ aspectRatio: '5 / 4', background: '#fff', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, border: '1px solid #f0f0f0', borderRight: 'none' }}>
                        <GaugeCharts period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                    <Col span={12} style={{ aspectRatio: '5 / 4', background: '#fff', borderTopRightRadius: 10, borderBottomRightRadius: 10, border: '1px solid #f0f0f0', borderLeft: 'none' }}>
                        <PieCharts period={resultPeriod} endingMonth={resultEndingMonth} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default NewSummary;
