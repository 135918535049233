import { Drawer, Space, Form } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useBoolean, useSafeState } from "ahooks";
import { CloseOutlined } from "@ant-design/icons";
import { PdfProvider, usePdfContext } from "./context";
import PdfTable from "./PdfTable";
import PdfForm, { periodOptions, savingAnalysisOptions, utilityOptions } from './PdfForm'
import PdfLoading from './PdfLoading'
import { ReviewPdf } from './ReviewPdf'
import moment from "moment";
import { useSelector } from "react-redux";

export const PdFStepMapping = {
  PDFForm: 0,
  PDFLoading: 1,
  PDFView: 2
}

const PdfReport = () => {
  const { pdfStep, isDrawerVisible, closeDrawer } = usePdfContext();

  const viewPDfStep = useMemo(() => {
    switch (pdfStep) {
      case PdFStepMapping.PDFForm:
        return <PdfForm />;
      case PdFStepMapping.PDFLoading:
        return <PdfLoading />;
      case PdFStepMapping.PDFView:
        return <ReviewPdf />;
      default:
        return <PdfForm />;
    }
  }, [pdfStep])  

  return (
    <div style={{ padding: 10 }}>
      <Drawer
        title="Report Builder"
        extra={
          <Space>
            <CloseOutlined onClick={closeDrawer} />
          </Space>
        }
        open={isDrawerVisible}
        placement="right"
        width='100%'
        closable={true}
        onClose={closeDrawer}
      >
        {viewPDfStep}
      </Drawer>
      <PdfTable />
    </div>
  );
};

const getDefaultPeriod = () => {
  return [moment().subtract(1, 'year'), moment()]
}

const getInitialValues = ({facilityName}) => ({
  name: `${facilityName} - FacilityKPI report ${moment().format("YYYY_MM_DD HH_mm")}`,
  utilityVisible: true,
  dashboardVisible: true,
  recentlyItem: utilityOptions.map((item) => item.id),
  recently: 12,
  endingMonth: moment().format('YYYY-MM'),
  period: getDefaultPeriod(),
  periodItem: periodOptions.map(item => item.id),
  savingAnalysisItem: savingAnalysisOptions.map(item => item.id),
  savingAnalysis: {
    elec_Option: "Baseline Average",
    gas_Option: "Baseline Average",
    elec_Baseline_Date: ["2021-12", "2022-11"],
    elec_Post_Date: ["2022-12", "2023-11"],
    gas_Baseline_Date: ["2021-12", "2022-11"],
    gas_Post_Date: ["2022-12", "2023-11"],
    gas_usedModuleValue: "Segments",
    elec_usedModuleValue: "Segments",
    gas_n_segments: 2,
    elec_n_segments: 2,
    breakpoints: [],
  }
});

const WithContextPdfReport = () => {

  const tableActionRef = useRef()
  const reloadTable = useCallback(() => {
    if(typeof tableActionRef.current?.reload === 'function') {
      tableActionRef.current.reload();
    }
  }, [])

  const [isDrawerVisible, { setTrue: openDrawer, setFalse: closeDrawer }] =
    useBoolean(false);

  const [pdfFormValue, setPdfFormValue] = useSafeState({})
  const [pdfImages, setPdfImages] = useSafeState({})
  const [pdfStep, setPdfStep] = useState(PdFStepMapping.PDFForm)
  const facilityName = useSelector((state) => state?.Facility?.selected?.value ?? '')
  const [form] = Form.useForm();

  const initForm = (values) => {

    form.setFieldsValue(values ? values : getInitialValues({facilityName}))
  }

  const goToFormStep = () => {
    setPdfStep(PdFStepMapping.PDFForm)
  }

  const goToLoadingStep = () => {
    setPdfStep(PdFStepMapping.PDFLoading)
  }

  const goToViewStep = () => {
    setPdfStep(PdFStepMapping.PDFView)
  }

  return (
    <PdfProvider
      value={{
        isDrawerVisible,
        openDrawer,
        closeDrawer,
        pdfFormValue,
        setPdfFormValue,
        pdfStep,
        goToFormStep,
        goToLoadingStep,
        goToViewStep,
        pdfImages,
        setPdfImages,
        reloadTable,
        tableActionRef,
        form,
        initForm
      }}
    >
      <PdfReport />
    </PdfProvider>
  );
};

export default React.memo(WithContextPdfReport);

