import React, {Component, Suspense} from 'react';
import ReactECharts from 'echarts-for-react';
import {Spinner} from "@components/Spinner";

class TreemapChart extends Component {
    constructor(props) {
        super(props);
    }

    getOption = () => {
        const data = this.props.data.map(d => ({
            name: d[this.props.name],
            value: d[this.props.value]
        }));

        const sortedData = [...data].sort((a, b) => b.value - a.value);
        const midPoint = Math.ceil(sortedData.length / 2);
        
        const leftTotal = sortedData.slice(0, midPoint).reduce((sum, item) => sum + item.value, 0);
        const rightTotal = sortedData.slice(midPoint).reduce((sum, item) => sum + item.value, 0);
        const maxTotal = Math.max(leftTotal, rightTotal);

        return {
            tooltip: {
                formatter: function(params) {
                    if (params.name !== 'left' && params.name !== 'right') {
                        return `${params.name}<br/>Value: ${params.value}`;
                    }
                    return '';
                }
            },
            series: [{
                type: 'treemap',
                data: [{
                    name: 'left',
                    value: maxTotal,
                    children: sortedData.slice(0, midPoint)
                }, {
                    name: 'right',
                    value: maxTotal,
                    children: sortedData.slice(midPoint)
                }],
                width: '100%',
                height: '100%',
                roam: false,
                nodeClick: false,
                squareRatio: 1,
                label: {
                    show: true,
                    position: 'inside',
                    formatter: '{b}',
                    fontSize: 12,
                    overflow: 'truncate'
                },
                breadcrumb: {
                    show: false
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                    gapWidth: 1
                },
                emphasis: {
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 2,
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.3)'
                    },
                    label: {
                        fontSize: 12,
                        fontWeight: 'normal',
                        formatter: '{b}',
                        overflow: 'truncate'
                    }
                },
                levels: [{
                    itemStyle: {
                        borderWidth: 0,
                        gapWidth: 5
                    }
                }, {
                    color: [
                        '#5B8FF9',
                        '#5AD8A6',
                        '#5D7092',
                        '#F6BD16',
                        '#E8684A',
                        '#6DC8EC',
                        '#9270CA',
                        '#FF9D4D',
                        '#269A99',
                        '#FF99C3'
                    ],
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1,
                        gapWidth: 1
                    }
                }],
                sort: false
            }]
        };
    }

    onChange = (e) => {
        let interval;
        if (e.target.value) {
            interval = e.target.value
        } else {
            interval = e
        }
        console.log('interval', this.props)
    }

    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <div className={'flex justify-between'}>
                    {/*<div className={'small-title_piechart'}>{this.props.title}</div>*/}
                    {/*<Radio.Group ... />*/}
                </div>
                <ReactECharts
                    option={this.getOption()}
                    style={{
                        height: this.props.height,
                        width: '100%'
                    }}
                    notMerge={true}
                />
            </Suspense>
        );
    }
}

export default TreemapChart;
