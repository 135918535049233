import React, { useMemo } from 'react';
import EChartsReact from "echarts-for-react";
import { useWeatherData } from '../hooks/useSummaryData';
import { groupBy } from 'lodash'
import moment from 'moment';
import { NormalizedCalculationsElec, NormalizedCalculationsGas } from '../../NormalizedCalculations';
import { TwoDecimalFormat } from '@/components/Format';
import { useSelector } from 'react-redux';

const chartData = [{ "Month": "23-Feb", "WattSqft": 133.63, "MonthlyAvgMeanOAT": 59.21 }, { "Month": "23-Jan", "WattSqft": 131.21, "MonthlyAvgMeanOAT": 47.75 }, { "Month": "22-Dec", "WattSqft": 134.83, "MonthlyAvgMeanOAT": 49.88 }, { "Month": "22-Nov", "WattSqft": 137.22, "MonthlyAvgMeanOAT": 52.31 }, { "Month": "22-Oct", "WattSqft": 148.94, "MonthlyAvgMeanOAT": 66.42 }, { "Month": "22-Sep", "WattSqft": 162.21, "MonthlyAvgMeanOAT": 77.69 }, { "Month": "22-Aug", "WattSqft": 181.71, "MonthlyAvgMeanOAT": 81.65 }, { "Month": "22-Jul", "WattSqft": 190.09, "MonthlyAvgMeanOAT": 90.87 }, { "Month": "22-Jun", "WattSqft": 185.94, "MonthlyAvgMeanOAT": 89.73 }, { "Month": "22-May", "WattSqft": 173.93, "MonthlyAvgMeanOAT": 80.2 }, { "Month": "22-Apr", "WattSqft": 158.25, "MonthlyAvgMeanOAT": 72.31 }, { "Month": "22-Mar", "WattSqft": 141.17, "MonthlyAvgMeanOAT": 64.66 }, { "Month": "22-Feb", "WattSqft": 138.14, "MonthlyAvgMeanOAT": 49.13 }, { "Month": "22-Jan", "WattSqft": 139, "MonthlyAvgMeanOAT": 45.33 }, { "Month": "21-Dec", "WattSqft": 140.92, "MonthlyAvgMeanOAT": 54.69 }, { "Month": "21-Nov", "WattSqft": 138.63, "MonthlyAvgMeanOAT": 57.67 }, { "Month": "21-Oct", "WattSqft": 144.42, "MonthlyAvgMeanOAT": 64.31 }, { "Month": "21-Sep", "WattSqft": 157.03, "MonthlyAvgMeanOAT": 78.56 }, { "Month": "21-Aug", "WattSqft": 171.78, "MonthlyAvgMeanOAT": 84.61 }, { "Month": "21-Jul", "WattSqft": 173.09, "MonthlyAvgMeanOAT": 85.87 }];

const groupByYear = groupBy(chartData, (item) => '20' + item.Month.split('-')[0])


const formatChartData = (data = [], type, area) => {
  return data.map(d => {
    let a = new Date(d['end_start_date']);
    let b = new Date(d['service_start_date']);
    let dateDiff = (a.getTime() - b.getTime()) / (1000 * 3600 * 24) + 1

    let yField = type === 'electricity' ? NormalizedCalculationsElec(
      Number((d["usage"]) + Number(d["adjustment"])),
      dateDiff,
      area
    ) :
      NormalizedCalculationsGas(
        Number((d["usage"]) + Number(d["adjustment"])),
        dateDiff,
        area
      )

    return {
      'Month': moment(d['service_month']).format('YY-MMM'),
      WattSqft: TwoDecimalFormat(yField) * 1,
      'MonthlyAvgMeanOAT': d["avgoat"]
    }
  })
}

const config = {
  gas: {
    title: 'Normalized Gas Usage Vs. OAT',
    name: 'Gas',
    unit: 'But/hr-sqft'
  },
  electricity: {
    title: 'Normalized Electricity Usage Vs. OAT',
    name: 'Electricity',
    unit: 'Watt/sqft'
  }
}

const colors = [
  '#A52A2A',  // 深棕色
  '#ADD8E6',  // 浅蓝色
  '#33FF57',  // 明亮的绿色
  '#FF33A1',  // 明亮的粉红色
  '#F1C40F'   // 明亮的黄色
];

export const NormalizedWeatherCharts = ({ type, period, endingMonth }) => {
  const Area = useSelector(state => state?.Facility?.selected?.record?.area)

  const { data } = useWeatherData(type, period, endingMonth)

  const chartData = formatChartData(data, type, Area)

  const groupByYear = groupBy(chartData, (item) => '20' + item.Month.split('-')[0])
  console.log(groupByYear)
  // const chartData = data[type]

  const option = {
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      type: 'value',
      name: 'Monthly Mean OAT (deg F)',
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        fontWeight: 'bold'
      },
      min: chartData.length > 0 ? function (value) { return value.min - 10; } : 30,
      max: chartData.length > 0 ? function (value) { return value.max + 10; } : 100,
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: function (value) {
          return parseInt(value);
        }
      }
    },
    yAxis: {
      type: 'value',
      name: config[type].unit,
      nameLocation: 'middle',
      nameGap: 40,
      nameTextStyle: {
        fontWeight: 'bold'
      },
      min: chartData.length > 0 ? function (value) { return value.min - 0.1 } : 2,
      max: chartData.length > 0 ? function (value) { return value.max + 0.1 } : 3,
      axisLabel: {
        formatter: function (value) {
          return parseFloat(value).toFixed(2);
        }
      }
    },
    series: Object.keys(groupByYear).map(year => {
      return {
        name: year,
        type: 'scatter',
        itemStyle: {
          color: colors[year % colors.length]
        },
        data: groupByYear[year].map(item => ({
          date: item.Month,
          name: item.Month.split('-')[0],
          value: [item.MonthlyAvgMeanOAT, item.WattSqft]
        })),
      }
    }),
    title: {
      text: config[type].title,
      left: 'center',
      top: '2%',
      textStyle: {
        color: '#396598',
        fontSize: 14
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `<span style="background:${params.color};width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:10px"></span>${'20' + params.data.date} <br/> Watt/Sqft: ${params.data.value[1]} <br/> OAT: ${params.data.value[0]}`;
      },
    },
    legend: {
      data: Object.keys(groupByYear),
      left: 'center',
      top: '12%',
      itemWidth: 10,
      itemHeight: 10
    },
    grid: {
      top: '20%',
      left: '6%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
  };

  return (
    <EChartsReact key={JSON.stringify(option)} option={option} style={{ width: '100%', height: '100%' }} />
  );
}