import React from "react";
import Tag from "antd/es/tag";



export const CurrencyFormat = (money, decimalPlaces = 2) => {
    
    if (money != null && money !== 0 && money !== '-') {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            currencySign: 'accounting',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
        }).format(money)
    } else {
        return null
    }
};

export const TwoDecimalFormat = (number) => {
    // console.log('number')
    if (number != null && number !== 0 && number !== '-') {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number)
    } else {
        return ''
    }
};

export const ThreeDecimalFormat = (number) => {
    if (number != null && number !== 0 && number !== '-') {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
        }).format(number)
    } else {
        return ''
    }
};

export const NoDecimalFormat = (number) => {
    if (number != null && number !== 0 && number !== '-') {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number)
    } else {
        return '-'
    }
};

export const SingleDecimalFormat = (number) => {
    if (number != null && number !== 0 && number !== '-') {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }).format(number)
    } else {
        return '-'
    }
};

export const PercentFormat = (ratio) => {
    if (ratio != null) {
        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(ratio);
    } else {
        return null
    }
}

export const SinglePercentFormat = (ratio) => {
    if (ratio != null) {
        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }).format(ratio);
    } else {
        return null
    }
}

export const CompactFormat = (num) => {
    if (num != null) {
        return new Intl.NumberFormat('en-US', {notation: "compact"}).format(num);
    } else {
        return null
    }
}

export const NegativeRed = (e) => {
    if (e && (e.startsWith('-') || e.startsWith('('))) {
        return <div style={{color: 'red'}}>
            {e}
        </div>;
    } else {
        return <div style={{color: 'green'}}>
            {e}
        </div>;
    }
}

export const FormatDate = (e) => {
    return new Intl.DateTimeFormat('en-US').format(new Date(e));
}

export const Today = () => {
    return new Intl.DateTimeFormat('en-US').format(new Date());
}

export const FormatMonth = (e) => {
    const date = new Date(e);
    const year = date.toLocaleString('en-US', {year: 'numeric'});
    const month = date.toLocaleString('en-US', {month: '2-digit'});
    return `${year}-${month}`;
}

export const RenderTag = (e) => {
    return <>
        {e.map(tag => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            return (
                <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                </Tag>
            )
        })}
    </>
}
