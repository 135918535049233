import React, { useRef, useEffect, useState } from 'react';
import { Scatter } from '@antv/g2plot';
import { DataView } from "@antv/data-set";
import { Spinner } from "@components/Spinner";
import _ from "lodash";
import LayoutContent from "@components/utility/layoutContent";
import { TwoDecimalFormat } from "@components/Format";
import moment from "moment";
import { NormalizedCalculationsElec, NormalizedCalculationsGas } from "../NormalizedCalculations";
import { ArrowsAltOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

const Histgram = (props) => {
    const chartRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.innerHTML = '';
            draw(props);
        }
    }, [props.data, props.selected]);

    const draw = (props) => {
        const chartDom = chartRef.current;
        const dv = new DataView().source(_.get(props, 'data', []));

        const data = dv.rows.map(d => {
            let a = new Date(d['end_start_date']);
            let b = new Date(d['service_start_date']);
            let dateDiff = (a.getTime() - b.getTime()) / (1000 * 3600 * 24) + 1

            let yField = (props.leftVar === 'WattSqft' ? NormalizedCalculationsElec(
                Number((d["usage"]) + Number(d["adjustment"])),
                dateDiff,
                props.area
            ) :
                NormalizedCalculationsGas(
                    Number((d["usage"]) + Number(d["adjustment"])),
                    dateDiff,
                    props.area
                )
            );
            return {
                'Month': moment(d['service_month']).format('YY-MMM'),
                [props.leftVar]: TwoDecimalFormat(yField) * 1,
                'MonthlyAvgMeanOAT': d["avgoat"]
            }
        })

        const columnPlot = new Scatter(chartDom, {
            data,
            xField: 'MonthlyAvgMeanOAT',
            yField: [props.leftVar],
            height: props.height,
            legend: props.legend || false,
            xAxis: {
                title: { text: 'Monthly Avg. OAT (deg F)' },
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            yAxis: {
                title: { text: props.leftTitle },
                nice: true,
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
                label: {
                    formatter: (text, item) => (TwoDecimalFormat(text)),
                },
            },
            // colorField: 'yValue',
            // color: (val) => {
            //     if (val.yValue > 90) {
            //         return '#1890ff'
            //     } else {
            //         return 'orange'
            //     }
            // },
            pointStyle: {
                lineWidth: 2,
            },
            shape: 'circle',
            size: props.pointSize,
        });

        columnPlot.render();

        columnPlot.on('element:click', (e) => {

            let data = _.get(e, 'data.data');
            onSelect(data)
        });

        columnPlot.on('legend-item:click', (e) => {

            if (props.isWeatherChart) {
                const options = e.view.options.legends.Month.items.map(item => ({
                    name: item.name,
                    checked: !item.unchecked
                }))

                const filteredData = data.filter(d => {
                    return options.some(o => d.Month.split('-')[0] === o.name.slice(2) && o.checked)
                })
                columnPlot.changeData(
                    filteredData
                )
            }
        });


        columnPlot.update({
            colorField: 'Month',
            color: (val) => {
                let isSelected = _.size(props.selected) > 0 ? props.selected.includes(val.Month) : false
                if (isSelected) {
                    return '#1890ff'
                    // return '#1c578f'
                } else {
                    if (typeof props.color === 'function') {
                        return props.color(val)
                    }
                    return props.color
                }
            }
        })


    }

    const onSelect = (e) => {
        let Id = _.get(e, 'Month')
        props.handleChartSelect(Id)
    }

    return (
        <React.Suspense fallback={<Spinner />}>
            <Modal title={props.title} open={isModalOpen} width={'80%'} maskClosable={false} onCancel={() => { setIsModalOpen(false) }}>
                <div ref={chartRef} />
            </Modal>
            <LayoutContent className={'outerCardWrapper'}>
                <div className={'flex justify-between'}>
                    <div className={'small-title pdfChart_Title'}>{props.title}</div>
                    <div className={'flex justify-between items-start cursor-pointer'} onClick={() => { setIsModalOpen(true) }}>
                        <ArrowsAltOutlined />
                    </div>
                </div>
                <div ref={chartRef} />
            </LayoutContent>
        </React.Suspense>
    );
}

export default Histgram;
