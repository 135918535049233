import React from "react";
import LayoutContent from "@components/utility/layoutContent";
import ReactECharts from 'echarts-for-react';

const BarChart = (props) => {
    const getOption = () => {
        const avgValue = props.data.reduce((sum, d) => sum + d.yField, 0) / props.data.length;
        
        return {
            xAxis: {
                type: 'category',
                data: props.data.map(item => item.key),
                axisLabel: {
                    show: false
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: props.data.map(item => ({
                    value: item.yField,
                    itemStyle: {
                        color: item.isCurrent ? '#fe9dc6' : '#5B8FF9'
                    }
                })),
                type: 'bar',
                barWidth: '60%',
                markLine: {
                    silent: true,
                    symbol: 'none',
                    label: {
                        show: false
                    },
                    data: [{
                        yAxis: avgValue,
                        lineStyle: {
                            color: '#f00',
                            width: 2,
                            type: 'dashed'
                        }
                    }]
                }
            }],
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    const data = props.data[params[0].dataIndex];
                    return `<ul style='margin:10px; padding:10px'>
                        <li style="padding-bottom:5px">Category: ${data.type}</li>
                        <li>EUI: ${data.yField}</li>
                    </ul>`;
                }
            },
            grid: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 40
            }
        };
    };

    return (
        <LayoutContent className={"outerCardWrapper"}>
            <div style={{marginBottom: '1rem'}}>
                <div className={'small-title_piechart'}>{props.title}</div>
                <span style={{top: '10px', right: '15px'}}
                      className="absolute p-2 mt-2 mr-2 rounded text-xs text-white">
                </span>
            </div>
            <ReactECharts 
                option={getOption()} 
                style={{ height: props.height }}
                notMerge={true}
            />
        </LayoutContent>
    );
};

export default BarChart;
