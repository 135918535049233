import moment from "moment"

export const safemomentmin = (...dates) => {
    const validDates = dates.map(date => moment(date)).filter(date => date.isValid())
    return validDates.length > 0 ? moment.min(...validDates) : moment().subtract(1, 'year')
}

export const safemomentmax = (...dates) => {
    const validDates = dates.map(date => moment(date)).filter(date => date.isValid())
    return validDates.length > 0 ? moment.max(...validDates) : moment()
}
