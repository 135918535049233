import React, {Component, Suspense} from 'react';
import ReactECharts from 'echarts-for-react';
import {Spinner} from "@components/Spinner";
import LayoutContent from "@components/utility/layoutContent";
import {NoDecimalFormat, SinglePercentFormat} from "@components/Format";
import _ from "lodash";
import styled from 'styled-components';

const ChartWrap = styled.div`
    height: 100%;
    width: 100%;
`

class PieChart extends Component {
    getOption = (props) => {
        const total = Math.round(props.data.reduce((p, v) => p + v.value, 0));
        
        return {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    return `${params.name}: ${NoDecimalFormat(params.value)} (${SinglePercentFormat(params.percent)})`
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '75%'],
                    center: ['50%', '55%'],
                    avoidLabelOverlap: true,
                    data: props.data.map(item => ({
                        name: item.type,
                        value: item.value,
                        itemStyle: {
                            color: props.colors?.[item.type] || undefined
                        }
                    })),
                    label: {
                        show: true,
                        formatter: function(params) {
                            return [
                                `{name|${params.name}}`,
                                `{value|${params.value} (${params.percent}%)}`
                            ].join('\n');
                        },
                        alignTo: 'edge',
                        position: 'outside',
                        distanceToLabelLine: 20,
                        edgeDistance: '5%',
                        rich: {
                            name: {
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: '#333',
                                padding: [0, 0, 5, 0],
                                lineHeight: 20
                            },
                            value: {
                                fontSize: 12,
                                color: '#666',
                                lineHeight: 16
                            }
                        }
                    },
                    labelLine: {
                        show: true,
                        smooth: true,
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    labelLayout: function(params) {
                        const isLeft = params.labelRect.x < window.innerWidth / 2;
                        const points = params.labelLinePoints;
                        points[2][0] = isLeft
                            ? params.labelRect.x
                            : params.labelRect.x + params.labelRect.width;
                        return {
                            labelLinePoints: points
                        };
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                },
                {
                    type: 'pie',
                    radius: ['0%', '30%'],
                    center: ['50%', '55%'],
                    data: [{
                        value: 100,
                        name: '',
                        itemStyle: {
                            color: 'transparent'
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: () => {
                                return `{title|Total}\n{total|${total}}`;
                            },
                            rich: {
                                title: {
                                    fontSize: 16,
                                    fontWeight: 600,
                                    padding: [0, 0, 10, 0]
                                },
                                total: {
                                    fontSize: 24,
                                    fontWeight: 600,
                                    padding: [5, 0, 0, 0]
                                }
                            }
                        }
                    }]
                }
            ]
        };
    };

    onChartClick = (params) => {
        if (params.data && this.props.handleChartClick) {
            this.props.handleChartClick({
                type: params.data.name,
                value: params.data.value
            });
        }
    };

    render() {
        const {height, title} = this.props;

        return (
            <Suspense fallback={<Spinner/>}>
                <LayoutContent className={'outerCardWrapper'}>
                    <div className={'flex justify-between'} style={{borderBottom: '1px solid #e9edf4'}}>
                        <div className={'small-title pdfChart_Title'}>{title}</div>
                    </div>
                    <ChartWrap>
                        <ReactECharts
                            style={{height: height}}
                            option={this.getOption(this.props)}
                            onEvents={{
                                'click': this.onChartClick
                            }}
                        />
                    </ChartWrap>
                </LayoutContent>
            </Suspense>
        );
    }
}

export default PieChart;
