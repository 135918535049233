import { Alert, Breadcrumb, Button, ConfigProvider, Divider, Drawer, Form, Input, message, Modal, Popconfirm, Segmented, Select, Space, Spin, Switch, Table, Tag, Tooltip, Tree, TreeSelect, Upload } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FileIcon, FolderIcon } from './Icon';
import { AppstoreOutlined, BarsOutlined, CloseOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, FolderAddOutlined, FolderFilled, FundViewOutlined, HighlightOutlined, HomeOutlined, MoreOutlined, ReloadOutlined, ScissorOutlined, SortAscendingOutlined, UploadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { FileManagementWrap, ActionBarWrap } from './style';
import _ from 'lodash';
import { api } from '@/redux/api';
import notification2 from '../Notification2';
import { jsforceResponse } from '@/config/httpService';
import { allowedFileTypes, beforeUpload, copyLink, formatFilesToTreeData, getPathsByCurrentTree, getSafeUrl, openInApp, openInBrowser, tablelocale, usePreviewUrl } from './utils';
import { useCounter, useSize } from 'ahooks';
import { FileViewer } from './doc-viewer';
import './index.css'
import moment from 'moment';
import { FormItemTag } from './FormItemTag';
import { CategoryOptions, CategoryTag } from './categoryTag';
import { DropdownMenu } from './DropdownMenu';
import { MoveToModal } from './MoveToModal';
import { useSelector } from 'react-redux';
import { CardTable } from './CardTable';
import ContextMenu from './ContextMenu';

const checkFileType = (file) => {
  const extension = file.name.split('.').pop().toLowerCase();
  if (allowedFileTypes.includes(extension)) {
    return true;
  }
  return false;
};

export const FileManagement = (props) => {

  // const [currentFiles, setCurrentFiles] = useState([]);
  const [searchKeyword, setSearchKeyWord] = useState('');
  const facility = useSelector(state => state.Facility.selected.Id);
  const isSearch = searchKeyword.length > 0;

  const [currentTreeKey, setCurrentTreeKey] = useState(null);

  const currentFileRef = useRef(null);

  const setCurrentTreeKeyWithClearSearch = (value) => {

    setCurrentTreeKey(value);
    setSearchKeyWord('')
    setSelectedRowKeys([])
  }

  const [treeData, setTreeData] = useState([]);
  const [leftWidth, setLeftWidth] = useState(null);
  const wrapRef = useRef(null);
  const leftWidthStyle = leftWidth ? { width: leftWidth } : { width: '20%' };
  const rightWidthStyle = leftWidth ? { width: `calc(100% - ${leftWidth}px)` } : { width: '80%' };

  const [newFolderOpen, setNewFolderOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isEditTagOpen, setIsEditTagOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isMoveToOpen, setIsMoveToOpen] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const [isPreview, setIsPreview] = useState(false)
  const [viewType, setViewType] = useState('card')

  const [currentOpenFile, setCurrentOpenFile] = useState();

  const [isCreatingFolderLoading, setIsCreatingFolderLoading] = useState(false);
  const [isUploadingFileLoading, setIsUploadingFileLoading] = useState(false);
  const [isReLoadLoading, setIsReLoadLoading] = useState(false);
  const [isRenamingLoading, setIsRenameLoading] = useState(false);

  const [newFolderForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [renameForm] = Form.useForm();
  const [tagForm] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const [moveForm] = Form.useForm()
  const [refreshKey, { inc: update }] = useCounter(0)
  const rootRef = useRef(null);
  const originalFilesRef = useRef({
    files: [],
    folders: []
  });
  // const clickTimerRef = useRef(null)

  const isFirstLoading = useRef(true)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [previewUrl, isFileViewLoading] = usePreviewUrl(currentOpenFile)
  const onSelectChange = (_, newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys.map(item => item.key),
    onChange: onSelectChange,
  };
  useEffect(() => {
    handleGetData()
  }, [])

  const onSelect = (_, e) => {
    setSearchKeyWord('')
    setCurrentTreeKeyWithClearSearch(e.selectedNodes[0])

  }

  const handleDrag = (e) => {
    const containerRect = wrapRef.current.getBoundingClientRect(); // 获取容器的边界
    const newWidth = e.clientX - containerRect.left; // 计算相对于容器的宽度
    setLeftWidth(newWidth > 0 ? newWidth : 0); // 更新宽度，确保不为负值
  };

  const currentFiles = useMemo(() => {

    if (isSearch) {
      return [].concat(originalFilesRef.current.folders, originalFilesRef.current.files).filter(item => item.name.includes(searchKeyword))

    } else if (currentTreeKey) {
      return currentTreeKey?.nodes ?? []
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTreeKey, searchKeyword])

  const handleCreateFolder = async () => {

    const { name } = await newFolderForm.validateFields().catch(() => ({}));

    if (name) {
      setIsCreatingFolderLoading(true)
      try {

        let parent = currentTreeKey?.id;
        let equipment = props.equipmentId;
        let table_name = props.tableName
        let record_id = props.recordId

        if (currentTreeKey.isSystem) {
          parent = null;
          equipment = currentTreeKey.context.equipmentId;
          table_name = currentTreeKey.context.table_name;
          record_id = currentTreeKey.context.record_id;
        } else if (props.tableName === 'All') {
          table_name = currentTreeKey.table_name
          record_id = currentTreeKey.record_id;
          equipment = currentTreeKey.equipment;
        } else {
          table_name = props.tableName
          record_id = props.recordId
          equipment = props.equipmentId
        }

        const result = await api.createFolder({
          name,
          table_name,
          ...(record_id ? { record_id } : {}),
          parent,
          facility,
          equipment
        });
        if (result.status === 201) {
          notification2.success({
            message: 'Folder created successfully',
            description: ''
          });
          // this.handleGetData();
        } else {
          notification2.error({
            message: 'Failed to create folder',
            description: result.data.message || ''
          });
        }
      } catch (error) {
        notification2.error({
          message: 'Failed to create folder',
          description: error.message || ''
        });
      }
      await refresh();
      setNewFolderOpen(false);
      setIsCreatingFolderLoading(false)
    }
  }

  const handleGetData = async () => {
    setIsReLoadLoading(true)
    let query = 'page=' + 1 + '&page_size=' + 9999 + '&table_name=' + props.tableName + (props.recordId ? ('&record_id=' + props.recordId) : '');
    const [filesResult, foldersResult] = await Promise.all([api.getFiles(query), api.getFolders(query)]);
    jsforceResponse(filesResult, '', false, true);
    jsforceResponse(foldersResult, '', false, true);

    if (filesResult.status === 200 && foldersResult.status === 200) {

      const [resultData, treeKey, originalFiles] = formatFilesToTreeData(_.get(filesResult, 'data.results') ?? [], _.get(foldersResult, 'data.results') ?? [], currentTreeKey?.id, props)
      setTreeData([resultData])
      rootRef.current = resultData;
      originalFilesRef.current = originalFiles;
      setCurrentTreeKeyWithClearSearch(treeKey)
      isFirstLoading.current = false;
      update();
      setIsReLoadLoading(false)
    }
  }

  const renderPath = () => {
    const arr = getPathsByCurrentTree(currentTreeKey)
    if (isSearch) {
      return <Breadcrumb separator="/">
        <Breadcrumb.Item
          style={{ cursor: 'pointer' }}
          onClick={() => setCurrentTreeKeyWithClearSearch(rootRef.current)}
        >
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item
        // style={{ cursor: 'pointer' }}
        // onClick={() => setCurrentTreeKeyWithClearSearch(rootRef.current)}
        >
          Search Results
        </Breadcrumb.Item>
      </Breadcrumb>
    }
    return (
      <Breadcrumb separator="/">
        <Breadcrumb.Item
          style={{ cursor: 'pointer' }}
          onClick={() => setCurrentTreeKeyWithClearSearch(rootRef.current)}
        >
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item
          style={{ cursor: 'pointer' }}
          onClick={() => setCurrentTreeKeyWithClearSearch(rootRef.current)}
        >
          root
        </Breadcrumb.Item>
        {
          arr.map(item => {
            return (
              <Breadcrumb.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentTreeKeyWithClearSearch(item);
                  // setCurrentFiles(item?.nodes.map(({ children, ...rest }) => rest) ?? [])
                }}
              >
                {item.name}
              </Breadcrumb.Item>
            );
          })
        }
      </Breadcrumb>
    )
  }

  const handleUpload = async () => {
    const values = await uploadForm.validateFields().catch(() => ({
      isErr: true,
    }));
    if (values.isErr) {
      return
    }
    const { tags, category, description, files } = values;
    // const file = values.file?.[0];
    if (files.length === 0) {
      notification2['error']({
        message: 'Please upload a file',
        description: ''
      });
      return
    }

    let record_id, equipment, table_name

    if (props.tableName === 'All') {
      if (currentTreeKey.isSystem) {

        record_id = currentTreeKey.context.record_id
        table_name = currentTreeKey.context.table_name
        equipment = currentTreeKey.context.equipmentId
      } else {
        record_id = currentTreeKey.record_id
        table_name = currentTreeKey.table_name
        equipment = currentTreeKey.equipment
      }
    } else {
      record_id = props.recordId
      table_name = props.tableName
      equipment = props.equipmentId
    }


    const body = {
      table_name,
      record_id,
      facility,
      files,
      category,
      description,
      // tags : JSON.stringify(Array.isArray(tags) ? tags : []),
      tags,
      equipment: props.tableName === 'All' ? currentTreeKey?.context?.equipmentId ?? props.equipment : props.equipmentId
    }
    if (!record_id) {
      delete body.record_id
    }

    if (currentTreeKey?.isSystem) {
      body.folder = null;
    } else if (currentTreeKey?.id) {
      body.folder = currentTreeKey?.id;
    }
    setIsUploadingFileLoading(true)
    let result1 = await api.uploadFile(body);
    jsforceResponse(result1, '', false, true)
    if (result1.status >= 200 && result1.status < 300) {
      await handleGetData()
      setIsUploadOpen(false);
      uploadForm.resetFields();
    }
    await refresh();
    setIsUploadingFileLoading(false)
  }

  const refresh = async () => {
    setSelectedRowKeys([])
    return await handleGetData()
  }

  const reload = async () => {
    setIsReLoadLoading(true)
    await handleGetData()
    setSelectedRowKeys([])
    setIsReLoadLoading(false)
  }

  const deleteFile = async (record) => {
    const { id, isFolder, record_id, table_name } = record;

    const fetch = isFolder ? api.deleteFolder : api.deleteFile;
    fetch(id, {
      record_id,
      table_name
    }).then(async (res) => {
      message.success('Deleted successfully');
      await refresh()
      setSelectedRowKeys([])
    })
  }

  const deleteSelected = async (e, body) => {
    Promise.all(selectedRowKeys.map(({ id, isFolder, record_id, table_name }) => {
      if (isFolder) {
        return api.deleteFolder(id, {
          ...(record_id ? { record_id } : {}),
          table_name
        })
      }
      return api.deleteFile(id, {
        ...(record_id ? { record_id } : {}),
        table_name
      })
    })).then(async (res) => {
      message.success('Deleted successfully');
      await refresh()
      setSelectedRowKeys([])
    })
  }

  const handleEdit = async () => {
    const currentFile = currentFileRef.current;
    if (!currentFile) {
      return
    };
    const { name, description, category, tags, file } = await editForm.validateFields().catch((err) => {
      return {}
    });

    if (name) {
      setIsRenameLoading(true);
      const fetch = currentFile.isFolder ? api.modifyFolder : api.modifyFile;

      const { id, record_id, table_name, type } = currentFile;
      const res = await fetch(id, { name, table_name, type, description, category, tags, file, ...(record_id ? { record_id } : {}) }).catch(() => ({}))
      if (res.status === 200) {
        if (selectedRowKeys[0]) {
          selectedRowKeys[0].name = name;
          selectedRowKeys[0].title = name;
        }
        refresh()
        setIsEditOpen(false)
      } else {
        message.error('error')
      }
      setIsRenameLoading(false)
    }
  }

  const handleDownload = async () => {

    for (const { image: downloadUrl, name } of selectedRowKeys) {
      const generate_url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
        response => {
          const url = response.data.url;
          return url
        })

      const link = document.createElement('a');
      link.href = generate_url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
    }
  }

  const onSearchKeywordChange = (e) => {
    setSearchKeyWord(e.target.value)
  }

  const openEditModal = (record) => {
    currentFileRef.current = record;

    if (currentFileRef.current.isFolder) {
      editForm.setFieldsValue({
        name: record.name,
        // tags: record.tags.map(item => item.id),
        // description: record.description,
        // category: record.category,
      })
    } else {
      editForm.setFieldsValue({
        name: record.name,
        tags: record.tags_list,
        description: record.description === 'null' ? '' : record.description,
        category: record.category === 'null' ? '' : record.category,
      })
    }

    setIsEditOpen(true)
  }

  const openEditTagModal = (record) => {
    currentFileRef.current = record;
    tagForm.setFieldsValue({
      // name: record.name,
      tags: record.tags_list,
      // description: record.description,
      // category: record.category,
    })
    setIsEditTagOpen(true)
  }
  const openRenameModal = (record) => {
    currentFileRef.current = record;
    renameForm.setFieldsValue({
      name: record.name,
      // tags: record.tags.map(item => item.id),
      // description: record.description,
      // category: record.category,
    })
    setIsRenameOpen(true)
  }

  const handleRename = async () => {
    const currentFile = currentFileRef.current;
    if (!currentFile) return;

    const { name } = await renameForm.validateFields().catch(() => ({}));
    if (name) {
      setIsRenameLoading(true);
      // const fetch = currentFile.isFolder ? api.modifyFolder : api.modifyFile;

      const { id, record_id, table_name, type, description, category, tags } = currentFile;
      let res;
      if (currentFile.isFolder) {
        res = await api.modifyFolder(id, { name, ...(record_id ? { record_id } : {}), table_name, type, description, category, tags, facility }).catch(() => ({}))

      } else {
        res = await api.modifyFile(id, { name, ...(record_id ? { record_id } : {}), table_name, type, description, category, tags }).catch(() => ({}))
      }
      if (res.status === 200) {
        if (selectedRowKeys[0]) {
          selectedRowKeys[0].name = name;
          selectedRowKeys[0].title = name;
        }
        refresh()
        setIsRenameOpen(false)
      } else {
        message.error('error')
      }
      setIsRenameLoading(false)
    }
  }

  const openMoveToModal = (record) => {
    setIsMoveToOpen(true)
    currentFileRef.current = record;
    moveForm.setFieldsValue({
      folder: record.folder
    })
  }

  const handleMoveTo = async () => {
    const currentFile = currentFileRef.current;
    const { id, type, description, category, tags, name } = currentFile;

    const fromData = await moveForm.validateFields().catch(err => {
      message.error('Please select a folder')
    });
    if (!fromData.folder) {
      return
    }

    const selectedFolder = originalFilesRef.current.folders.find(item => item.id === fromData.folder)
    const folder = fromData.folder
    let record_id, table_name, equipment
    if (selectedFolder.isSystem) {
      record_id = selectedFolder.context.record_id
      table_name = selectedFolder.context.table_name
      equipment = selectedFolder.context.equipmentId
    } else {
      record_id = currentFile.record_id
      table_name = currentFile.table_name
      equipment = currentFile.equipment
    }

    const fetch = selectedFolder.isFolder ? api.modifyFolder : api.modifyFile;

    const body = {
      name,
      record_id,
      table_name,
      equipment,
      type,
      description,
      category,
      tags,
      folder: selectedFolder.isSystem ? null : folder
    }
    if (!body.record_id) {
      delete body.record_id
    }

    const res = await fetch(id, body, true)
    if (res.status === 200) {
      refresh()
      setIsMoveToOpen(false)
    } else {
      message.error('error')
    }
  }

  const handleEditTag = async () => {
    const currentFile = currentFileRef.current;
    if (!currentFile) return;

    const { tags } = await tagForm.validateFields().catch(() => ({}));
    if (currentFile?.name) {
      setIsRenameLoading(true);
      const fetch = currentFile.isFolder ? api.modifyFolder : api.modifyFile;
      const { id, record_id, table_name, type, description, category, name } = currentFile;
      const res = await fetch(id, { name, record_id, table_name, type, description, category, tags }).catch(() => ({}))
      if (res.status === 200) {
        if (selectedRowKeys[0]) {
          selectedRowKeys[0].name = name;
          selectedRowKeys[0].title = name;
        }
        refresh()
        setIsEditTagOpen(false)
      } else {
        message.error('error')
      }
      setIsRenameLoading(false)
    }
  }


  const columns = [
    {
      width: 26,
      dataIndex: '',
      ellipsis: true,
      render(_, item) {
        return <div className='icon-wrap'><FileIcon isFolder={item.isFolder} title={item.title} /></div>
      }
    },
    {
      title: 'name',
      dataIndex: 'title',
      width: 326,
      sorter(a, b) {
        return a.title.localeCompare(b.title)
      },
      render: (text, record) => {
        return (
          <div
            style={{ position: 'relative', width: '100%' }}
          >
            {record.isSystem ? null : <div className='NameDropdownMenu' style={{ position: 'absolute', right: 0, top: 0 }}>
              <DropdownMenu
                record={record}
                currentFileRef={currentFileRef}
                setIsEditOpen={setIsEditOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                setIsFileOpen={setIsFileOpen}
                setCurrentOpenFile={setCurrentOpenFile}
                setCurrentTreeKeyWithClearSearch={setCurrentTreeKeyWithClearSearch}
                reload={reload}
                openEditModal={openEditModal}
                deleteFile={deleteFile}
                openEditTagModal={openEditTagModal}
                openRenameModal={openRenameModal}
                openMoveToModal={openMoveToModal}
              />
            </div>}
            <span className="name-text">{text}</span>

          </div>
        );
      },
    },
    {
      title: 'Tags',
      dataIndex: 'tags_list',
      render(record = [], row) {
        if (row.isFolder) {
          return null
        }
        if (Array.isArray(record)) {
          return <Tooltip title={<div style={{ maxHeight: 600, overflowY: 'auto' }}>{record.map(item => <div>{item}</div>)}</div>}>
            <div style={{ maxWidth: 600, textOverflow: 'ellipsis', overflow: 'hidden', }}>{record.map(item => <Tag>{item}</Tag>)}</div>
          </Tooltip>
        }
        return null
      }
    },
    {
      title: 'category',
      dataIndex: 'category',
      key: 'category',
      render(record, row) {
        if (row.isFolder) {
          return null
        }
        return <CategoryTag type={record} />
      }
    },
    // {
    //   title: 'type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   // width: 100,
    //   render(r) {
    //     return <Tooltip title={r}>
    //       <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{r}</div>
    //     </Tooltip>
    //   }
    // },
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      // width: '8rem',
      key: 'created_date',
      sorter: (a, b) => {
        let collator = new Intl.Collator();
        return collator.compare(a.modified_date, b.modified_date);
      },
      render: (text, record) => (<div>{record.isSystem ? '' : moment(text).format('YYYY-MM-DD HH:mm')}</div>)
    },
    {
      title: 'Created By',
      key: 'createdby_name',
      // width: '8rem',
      dataIndex: 'createdby_name',
    },
    {
      title: 'description',
      key: 'description',
      // width: '8rem',
      dataIndex: 'description',
      render(record, row) {
        if (row.isFolder) {
          return null
        }
        if (record === "undefined") {
          return null
        }
        return record || ''
      }
    }
  ];

  const onRow = record => {
    return {
      onDoubleClick(e) {
        if (e.ctrlKey) {
          return
        }
        const element = e.target.closest('.ant-checkbox-wrapper')
        if (element) {
          return
        }

        if (record.isFolder) {
          setCurrentTreeKeyWithClearSearch(record)
        } else {
          setIsFileOpen(true)
          setCurrentOpenFile(record)
        }
      },
      onClick(e) {
        const element = e.target.closest('.ant-checkbox-wrapper')
        if (element) {
          return
        }
        setCurrentOpenFile(record)
        if (e.ctrlKey) {
          const currentSelectedRow = selectedRowKeys.find(item => item.key === record.key)
          if (currentSelectedRow) {
            setSelectedRowKeys(selectedRowKeys.filter(item => item.key !== record.key))
          } else {
            setSelectedRowKeys([...selectedRowKeys, record])
          }

        }
      },
      onContextMenu(e) {
        e.preventDefault()

        const currentSelectedRow = selectedRowKeys.find(item => item.key === record.key)
        if (!currentSelectedRow) {
          setSelectedRowKeys([record])
        }

        setIsContextMenuOpen(true)
        setContextMenuPosition({ x: e.clientX, y: e.clientY })
      }
    }
  }

  const size = useSize(document.body);
  const height = size.height - 120;

  const canCreateFolder = currentTreeKey?.isSystem ? currentTreeKey?.context?.canCreate : true
  const canUploadFile = currentTreeKey?.isSystem ? currentTreeKey?.context?.canUpload : true
  const canDelete = selectedRowKeys.every(item => !item.isSystem)

  const onMenuItemClick = async (menuItem) => {
    setIsContextMenuOpen(false)
    switch (menuItem.key) {
      case 'OpenFolder':
        setCurrentTreeKeyWithClearSearch(selectedRowKeys[0])
        break;
      case 'Open':
        break;
      case 'OpenInBrowser':
        openInBrowser(selectedRowKeys[0])
        break;
      case 'OpenInApp':
        openInApp(selectedRowKeys[0])
        break;
      case 'Edit':
        openEditModal(selectedRowKeys[0])
        break;
      case 'Rename':
        openRenameModal(selectedRowKeys[0])
        break;
      case 'EditTags':
        openEditTagModal(selectedRowKeys[0])
        break;
      case 'Download':
        for (const item of selectedRowKeys) {
          await handleDownload(item)
        }
        break;
      case 'Refresh':
        reload()
        break;
      case 'Delete':
        for (const item of selectedRowKeys) {
          await deleteFile(item)
        }
        break;
      case 'CopyLink':
        copyLink(selectedRowKeys[0])
        break;
      case 'MoveTo':
        openMoveToModal(selectedRowKeys[0])
        break;
    }
  }

  return (
    <>
      <ActionBarWrap>
        <Space>
          {
            selectedRowKeys?.length === 0 ? <>
              <Button disabled={!currentTreeKey?.parentFolder} style={{ marginRight: 10 }} onClick={() => setCurrentTreeKeyWithClearSearch(currentTreeKey?.parentFolder)}><VerticalAlignTopOutlined /></Button>

              {
                canCreateFolder && <Button disabled={isSearch} style={{ marginRight: 10 }} onClick={() => {
                  newFolderForm.setFieldsValue({
                    name: 'New Folder',
                    path: [].concat('root', getPathsByCurrentTree(currentTreeKey).map(item => item.name)).join('/')
                  })
                  setNewFolderOpen(true)
                }}><FolderAddOutlined />Create Folder</Button>
              }
              {
                canUploadFile && <Button style={{ marginRight: 10 }} disabled={isSearch} onClick={() => setIsUploadOpen(true)}><UploadOutlined />Upload</Button>
              }
              <Button icon={<ReloadOutlined />} loading={isReLoadLoading} disabled={isReLoadLoading} style={{ marginRight: 10 }} onClick={reload} >Reresh</Button>
            </> : <>
              {selectedRowKeys?.length === 1 ? <>
                {selectedRowKeys[0].isSystem ? null : <Button icon={<ScissorOutlined />} onClick={() => openMoveToModal(selectedRowKeys[0])}>Move To</Button>}
                <Button icon={<HighlightOutlined />} onClick={() => openRenameModal(selectedRowKeys[0])}>Rename</Button>
                {
                  canDelete && <Popconfirm
                    title="Do you want to delete this record"
                    open={isDeleteOpen}
                    onConfirm={() => {
                      deleteSelected(selectedRowKeys);
                      setIsDeleteOpen(false);
                    }}
                    onCancel={() => setIsDeleteOpen(false)}
                  >
                    <Button onClick={() => setIsDeleteOpen(true)} icon={<DeleteOutlined />}>Delete</Button>
                  </Popconfirm>
                }
                {selectedRowKeys.every(item => item.isFolder === false) && selectedRowKeys.length !== 0 ? <Button icon={<DownloadOutlined />} onClick={handleDownload}>Download</Button> : null}
              </> :
                <>
                  {
                    canDelete && <Popconfirm
                      title="Do you want to delete this record"
                      open={isDeleteOpen}
                      onConfirm={() => {
                        deleteSelected(selectedRowKeys);
                        setIsDeleteOpen(false);
                      }}
                      onCancel={() => setIsDeleteOpen(false)}
                    >
                      <Button onClick={() => setIsDeleteOpen(true)} icon={<DeleteOutlined />}>Delete</Button>
                    </Popconfirm>
                  }

                  {selectedRowKeys.every(item => item.isFolder === false) && selectedRowKeys.length !== 0 ? <Button icon={<DownloadOutlined />} onClick={handleDownload}>Download</Button> : null}
                </>
              }

            </>
          }

        </Space>
        <Space>
          <Segmented
            value={isPreview}
            onChange={setIsPreview}
            options={[
              {
                value: false,
                icon: <EyeInvisibleOutlined />,
              },
              {
                value: true,
                icon: <EyeOutlined />,
              },
            ]}
            style={{ marginRight: 10 }}
          />
          <Segmented
            value={viewType}
            onChange={setViewType}
            options={[
              {
                value: 'card',
                icon: <AppstoreOutlined />,
              },
              {
                value: 'list',
                icon: <BarsOutlined />,
              },
            ]}
            style={{ marginRight: 10 }}
          />
        </Space>

      </ActionBarWrap>
      <FileManagementWrap ref={wrapRef}>
        <div className='left' style={{ paddingLeft: '10px', ...leftWidthStyle }}>
          {isFirstLoading.current ? <Spin style={{ width: '100%', marginTop: 200 }} /> : <Tree.DirectoryTree
            loading={isReLoadLoading}
            key={refreshKey}
            defaultExpandAll
            treeData={treeData}
            onSelect={onSelect}
            icon={(props) => <FolderIcon isOpen={props.expanded && props.data?.children?.length > 0} isSystem={props.data?.isSystem} />}
            switcherIcon={null}
            className='left-tree'
            checkStrictly={true}
            height={height}
            selectedKeys={currentTreeKey?.key ? [currentTreeKey.key] : []}
          />}

          {/* <Divider type="vertical" style={{ height: '100%' }} /> */}
        </div>
        <div
          onMouseDown={(e) => {
            document.addEventListener('mousemove', handleDrag);
            const handleMouseUp = () => {
              document.removeEventListener('mousemove', handleDrag);
              document.removeEventListener('mouseup', handleMouseUp); // 清除mouseup事件
            };
            document.addEventListener('mouseup', handleMouseUp); // 添加mouseup事件
          }}
          style={{
            cursor: 'col-resize',
            backgroundColor: '#ccc',
            width: '6px',
            height: 'calc(100vh - 80)',
            marginRight: 10
          }}
        />
        <div className='right' style={{ ...rightWidthStyle }}>
          <div className='action-wrap'>
            <div>
              {renderPath()}
            </div>
            <div className='search-bar'>
              <Input.Search value={searchKeyword} onChange={onSearchKeywordChange} style={{ width: 240 }} placeholder="search" enterButton />
            </div>
          </div>
          {/* <div className='action-wrap'>
            <div className='left-bar'>
              <Button disabled={!currentTreeKey?.parentFolder} style={{ marginRight: 10 }} onClick={() => setCurrentTreeKeyWithClearSearch(currentTreeKey?.parentFolder)}><VerticalAlignTopOutlined /></Button>

              <Button disabled={isSearch} style={{ marginRight: 10 }} onClick={() => {
                newFolderForm.setFieldsValue({
                  name: 'New Folder',
                  path: [].concat('root', getPathsByCurrentTree(currentTreeKey).map(item => item.name)).join('/')
                })
                setNewFolderOpen(true)
              }}><FolderAddOutlined />Create Folder</Button>
              <Button style={{ marginRight: 10 }} disabled={isSearch} onClick={() => setIsUploadOpen(true)}><UploadOutlined />Upload</Button>

            </div>
            <div className='action-bars'>
              <Button disabled={selectedRowKeys.length !== 1} style={{ marginRight: 10 }} onClick={() => {
                openEditModal(selectedRowKeys[0])


              }}>
                <EditOutlined />
              </Button>
              <Button disabled={!(selectedRowKeys.every(item => item.isFolder === false) && selectedRowKeys.length !== 0)} style={{ marginRight: 10 }} onClick={handleDownload}>
                <DownloadOutlined />
              </Button>
              <div style={{ marginRight: 10 }}>
                <Popconfirm
                  title="Do you want to delete this record"
                  open={isDeleteOpen}
                  onConfirm={() => {
                    deleteSelected(selectedRowKeys);
                    setIsDeleteOpen(false);
                  }}
                  onCancel={() => setIsDeleteOpen(false)}
                >
                  <Button disabled={selectedRowKeys.length === 0} onClick={() => setIsDeleteOpen(true)}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>
              <Segmented

                value={isPreview}
                onChange={setIsPreview}
                options={[
                  {
                    value: false,
                    icon: <EyeInvisibleOutlined />,
                  },
                  {
                    value: true,
                    icon: <EyeOutlined />,
                  },
                ]}
                style={{ marginRight: 10 }}
              />
              <Button loading={isReLoadLoading} disabled={isReLoadLoading} style={{ marginRight: 10 }} onClick={reload}><ReloadOutlined /></Button>

            </div>

          </div> */}
          {
            searchKeyword && <Alert
              style={{ marginBottom: 10 }}
              message={`Search completed! Your search for '${searchKeyword}', results: ${currentFiles.length} matches found.`}
              type="warning"
              showIcon
              closable={false}
              onClose={() => { }}
              action={<CloseOutlined onClick={() => setSearchKeyWord('')} />}
            />
          }

          <div className='content'>
            <div className='file-list'>
              <ConfigProvider >
                {
                  viewType === 'card' ?
                    <CardTable
                      dataSource={currentFiles}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeys={setSelectedRowKeys}
                      onRow={onRow}
                    /> : <Table
                      loading={isReLoadLoading}
                      rowSelection={rowSelection}
                      dataSource={currentFiles.map(({ children, ...rest }) => rest)}
                      columns={columns}
                      pagination={false}
                      onRow={onRow}
                      locale={tablelocale(isSearch)}
                    />
                }

              </ConfigProvider>
            </div>
            {
              isPreview && <div className='preview-wrap'>
                <Divider type="vertical" style={{ height: '100%' }} />
                <div className='preview'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spin spinning={isFileViewLoading}>
                    <FileViewer url={previewUrl} currentOpenFile={currentOpenFile} />
                    {/* { isImage(previewUrl) ? <img style={{width: '100%'}} src={previewUrl} alt='' /> : <iframe title="preview" style={{width: '100%'}} src={previewUrl} /> } */}
                  </Spin>

                </div>
              </div>
            }
          </div>
        </div>

        <Modal
          title="New Folder"
          open={newFolderOpen}
          onOk={() => {
            handleCreateFolder()
          }}
          destroyOnClose={true}
          okButtonProps={{
            loading: isCreatingFolderLoading,
            disabled: isCreatingFolderLoading,
          }}
          onCancel={() => setNewFolderOpen(false)}
        >
          <Form form={newFolderForm}>
            <Form.Item label="Name" name='name' rules={[
              { required: true, message: 'Please input folder name' }, {
                validator: (_, value) => {
                  if (currentFiles.some(item => item.name === value)) {
                    return Promise.reject('This folder name is already in use.')
                  }
                  return Promise.resolve();
                }
              }]}>
              <Input placeholder="Folder Name" />
            </Form.Item>
            <Form.Item
              label={<span className="create-info-form-label">Path</span>}
              name="path"
            // initialValue={this.getPath()}
            >
              <Input disabled prefix={<FolderFilled style={{ color: '86909C', marginRight: 4 }} />} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Edit File"
          open={isEditOpen}
          onCancel={() => {
            setIsEditOpen(false);
            currentFileRef.current = selectedRowKeys[0]
          }}
          destroyOnClose={true}
          okButtonProps={{
            loading: isRenamingLoading,
            disabled: isRenamingLoading
          }}
          onOk={handleEdit}
        >
          <Form form={editForm} layout='vertical'>
            <Form.Item label="Name" name='name' rules={[{ required: true, message: 'Please input new name' },
            {
              validator(_, value) {
                if (value === currentFileRef.current.name) {
                  return Promise.resolve()
                }
                if (currentFiles.some(item => item.name === value)) {
                  return Promise.reject('This file name is already in use.')
                }
                return Promise.resolve();
              }
            }
            ]}>
              <Input placeholder="New Name" />
            </Form.Item>
            {!selectedRowKeys?.[0]?.isFolder && <>
              <Form.Item label="Tags" name="tags">
                <FormItemTag record={selectedRowKeys[0]} />
              </Form.Item>
              <Form.Item label="category" name="category">
                <Select options={CategoryOptions} />
              </Form.Item>
              <Form.Item
                name="file"
                label={<div>Upload File<span style={{ color: 'red', marginLeft: 10 }}>{editForm.getFieldValue('file') ? 'This operation may overwrite the original file!' : ''}</span></div>}
                valuePropName="file"
                rules={[{
                  validator() {
                    const file = editForm.getFieldValue('file')
                    if (!file) {
                      return Promise.resolve()
                    }
                    const isValidedFile = checkFileType(file)
                    if (!isValidedFile) {
                      return Promise.reject(`The file \`${file.name}\` is not allowed to upload.please check the file extension.`)
                    }
                    return Promise.resolve();
                  }
                }]}
                getValueFromEvent={(e) => {
                  return e.file
                  // if (Array.isArray(e)) {
                  //   return e;
                  // }
                  // if(Array.isArray(e?.fileList)) {
                  //   return e.fileList;
                  // }
                  // return []; // 确保返回 fileList
                }}
              >
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  onChange={({ file, fileList }) => {
                    editForm.setFieldsValue({ file: fileList[0] });
                    update();
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item label="description" name="description">
                <Input />
              </Form.Item>
            </>
            }
          </Form>
        </Modal>

        <Modal title="Edit Tag" open={isEditTagOpen} onCancel={() => setIsEditTagOpen(false)} onOk={handleEditTag}
          okButtonProps={{
            loading: isReLoadLoading,
            disabled: isRenamingLoading,
          }}
        >
          <Form form={tagForm} layout='vertical'>
            <Form.Item label="Tags" name="tags">
              <FormItemTag record={currentFileRef.current} />
            </Form.Item>
          </Form>

        </Modal>
        <Modal title="Rename" open={isRenameOpen} onCancel={() => setIsRenameOpen(false)} onOk={handleRename}
          okButtonProps={{
            loading: isReLoadLoading,
            disabled: isRenamingLoading,
          }}
        >
          <Form form={renameForm} layout='vertical'>
            <Form.Item label="Name" name='name' rules={[{ required: true },
            {
              validator(_, value) {
                if (value === currentFileRef.current.name) {
                  return Promise.resolve()
                }
                if (currentFiles.some(item => item.name === value)) {
                  return Promise.reject('This file name is already in use.')
                }
                return Promise.resolve();
              }
            }
            ]}>
              <Input placeholder="New Name" />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={isUploadOpen}
          title="Upload a File"
          onCancel={() => {
            setIsUploadOpen(false);
            uploadForm.resetFields();
          }}
          onOk={handleUpload}
          okButtonProps={{
            loading: isUploadingFileLoading,
            disabled: isUploadingFileLoading,
          }}
          destroyOnClose
        >
          <Form
            form={uploadForm}
            layout="vertical"
            // onFinish={handleFinish}
            autoFocusFirstInput
          >
            <Form.Item label="Tags" name="tags">
              <FormItemTag record={currentTreeKey} />
            </Form.Item>
            <Form.Item label="category" name="category">
              <Select options={CategoryOptions} />
            </Form.Item>

            <Form.Item
              name="files"
              label="Upload File"
              valuePropName="fileList"
              getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
              rules={[{
                validator(_, files) {
                  let validName
                  const isValid = currentFiles.some(c => {
                    return files.some(f => {
                      if (c.name === f.name) {
                        validName = c.name
                        return true
                      } else {
                        return false
                      }
                    })
                  })

                  if (isValid) {
                    return Promise.reject(`File name ${validName} is already in use.`)
                  }

                  for (const file of files) {
                    const isValidedFile = checkFileType(file)
                    if (!isValidedFile) {
                      return Promise.reject(`The file \`${file.name}\` is not allowed to upload.please check the file extension.`)
                    }
                  }

                  return Promise.resolve();
                }
              }]}
            >

              <Upload.Dragger
                multiple={true}
                beforeUpload={beforeUpload}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </Form.Item>

            <Form.Item label="description" name="description">
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <MoveToModal treeData={treeData} open={isMoveToOpen} setOpen={setIsMoveToOpen} moveForm={moveForm} handleMoveTo={handleMoveTo} />
        <Drawer
          title="view file"
          open={isFileOpen}
          onClose={() => setIsFileOpen(false)}
          destroyOnClose={true}
          width="100%"
          height="90vh"
        >
          <FileViewer url={previewUrl} currentOpenFile={currentOpenFile} />
        </Drawer>
      </FileManagementWrap>
      <ContextMenu onMenuItemClick={onMenuItemClick} x={contextMenuPosition.x} y={contextMenuPosition.y} isContextMenuOpen={isContextMenuOpen} setIsContextMenuOpen={setIsContextMenuOpen} selectedRowKeys={selectedRowKeys} />
    </>
  )
}