import React from "react";
import EChartsReact from "echarts-for-react";
import { useSummaryData } from "../hooks/useSummaryData";

export const PieCharts = ({ period, endingMonth }) => {

    const { data: electricityData } = useSummaryData('electricity', period, endingMonth)
    const { data: gasData } = useSummaryData('gas', period, endingMonth)

    const option = {
        series: [
            {
                name: 'Nightingale Chart',
                type: 'pie',
                radius: ['0%', '78%'],
                center: ['50%', '55%'],
                itemStyle: {
                    borderRadius: 0
                },
                data: [
                    { value: parseInt(electricityData?.annual_eui || 0), name: 'Electricity', itemStyle: { color: '#69ba5c' } },
                    { value: parseInt(gasData?.annual_eui || 0), name: 'Gas', itemStyle: { color: '#f67e38' } },
                ],
                label: {
                    show: true,
                    position: 'inside',
                    formatter: function (params) {
                        return [
                            `{name|${params.name}}`,
                            `{value|${params.value}}`
                        ].join('\n');
                    },
                    rich: {
                        name: {
                            fontSize: 12,
                            lineHeight: 16,
                            color: '#fff'
                        },
                        value: {
                            fontSize: 14,
                            lineHeight: 16,
                            color: '#fff',
                            fontWeight: 'bold'
                        }
                    }
                },
                labelLine: {
                    show: false
                }
            }
        ],
        legend: {
            show: false,
        },
        title: {
            text: 'EUI Breakdown (kBtu/sqft-yr)',
            left: 'center',
            top: '6%',
            textStyle: {
                color: '#396598',
                fontSize: 14
              }
        },
    };

    return (

        <EChartsReact option={option} style={{ width: '100%', height: '100%' }} />
    );
};