import React from 'react';
import PermissionControl from '@/components/Permission/Permission';

import Facility from '@/containers/management/Facility/Facility';
import EquipmentCategory from '@/containers/facility/Equipment_Category/Equipment_Category';
import FAQ from '@/containers/Help/FAQ';
import Company from '@/containers/management/Company/Company';

import CustomUser from "@containers/management/UserManagement/CustomUser/CustomUser";
import Profile from "@containers/management/UserManagement/Profile/Profile";

// import UserManagementTabs from '@/containers/management/UserManagement/UserManagementTabs';
import FunctionalForm from '@/containers/management/FunctionalForm/FunctionalForm';
import FileTagManage from '@/containers/management/FileTagManage/FileTagManage';
import UserDetail from '@/containers/management/UserManagement/UserDetail';
import CreateUser from '@/containers/management/UserManagement/CreateUser';

export const companyRoutes = [
  {
    path: 'Company',
    element: <PermissionControl allowedPermissions={'1:company'}><Company /></PermissionControl>,
    name: 'company',
    handle: {
      tab: 'Company'
    }
  },
  {
    path: 'FAQ',
    element: <PermissionControl allowedPermissions={'1:faq'}><FAQ /></PermissionControl>,
    name: 'faq',
    handle: {
      tab: 'Faq'
    }
  },
  // {
  //   path: 'CustomUser',
  //   element: <PermissionControl allowedPermissions={'1:user'}><CustomUser /></PermissionControl>,
  //   name: 'user',
  //   handle: {
  //     tab: 'User'
  //   }
  // },
  {
    path: 'Equipment_Category',
    element: <PermissionControl allowedPermissions={'1:equipmentCategory'}><EquipmentCategory /></PermissionControl>,
    name: 'equipmentCategory',
    handle: {
      tab: 'Equipment Category'
    }
  },
  {
    path: 'Facility',
    element: <PermissionControl allowedPermissions={'1:facility'}><Facility /></PermissionControl>,
    name: 'facility',
    handle: {
      tab: 'Facility'
    }
  },
  // {
  //   path: 'profile',
  //   element: <PermissionControl allowedPermissions={'1:profile'}><Profile /></PermissionControl>,
  //   name: 'profile',
  //   handle: {
  //     tab: 'Profile'
  //   }
  // },
  {
    path: 'UserManagementTabs',
    element: <PermissionControl allowedPermissions={'1:user'}><CustomUser /></PermissionControl>,
    name: 'user',
    handle: {
      tab: 'User'
    }
  },
  {
    path: 'ProfileManagementTabs',
    element: <PermissionControl allowedPermissions={'1:user'}><Profile /></PermissionControl>,
    name: 'profilepermission',
    handle: {
      tab: 'Profile Permission'
    }
  },
  {
    path: 'functionform',
    element: <PermissionControl allowedPermissions={'1:functionform'}><FunctionalForm /></PermissionControl>,
    name: 'functionform',
    handle: {
      tab: 'Function Form'
    }
  },
  {
    path: 'filetagmanage',
    element: <PermissionControl allowedPermissions={'1:filetagmanage'}><FileTagManage /></PermissionControl>,
    name: 'filetagmanage',
    handle: {
      tab: 'File Tags'
    }
  },
  {
    path: 'UserDetail/:id',
    element: <PermissionControl allowedPermissions={'1:user'}><UserDetail /></PermissionControl>,
    name: 'User Detail',
    handle: {
      tab: 'User Detail'
    }
  },
  {
    path: 'CreateUser',
    element: <PermissionControl allowedPermissions={'1:user'}><CreateUser /></PermissionControl>,
    name: 'Create User',
    handle: {
      tab: 'Create User'
    }
  }
]

