import React, { useRef } from 'react'
import { Menu } from 'antd'
import styled from 'styled-components'
import { useClickAway } from 'ahooks';
import {
    AppstoreOutlined,
    ChromeOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    FileOutlined,
    FolderOpenOutlined,
    HighlightOutlined,
    ReloadOutlined,
    ScissorOutlined,
    ShareAltOutlined,
    TagOutlined
} from '@ant-design/icons';
import { canOpenInApp } from './utils';

const StyleMenu = styled(Menu)`
    .ant-menu-item {
        margin: 0 !important;
    }
    .ant-menu-item:hover {
        background-color: #e6f7ff !important;
    }
`

const map = new Map(
    [
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && selectedRowKeys[0].isFolder && !selectedRowKeys[0].isSystem,
            { key: 'OpenFolder', icon: <FolderOpenOutlined />, label: 'Open Folder' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && !selectedRowKeys[0].isFolder && !selectedRowKeys[0].isSystem,
            { key: 'Open', icon: <FileOutlined />, label: 'Open' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && canOpenInApp(selectedRowKeys[0]),
            { key: 'OpenInBrowser', icon: <ChromeOutlined />, label: 'Open in Browser' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && canOpenInApp(selectedRowKeys[0]),
            { key: 'OpenInApp', icon: <AppstoreOutlined />, label: 'Open in App' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && !selectedRowKeys[0].isSystem,
            { key: 'Edit', icon: <EditOutlined />, label: 'Edit' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && !selectedRowKeys[0].isSystem,
            { key: 'Rename', icon: <HighlightOutlined />, label: 'Rename' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && !selectedRowKeys[0].isSystem,
            { key: 'EditTags', icon: <TagOutlined />, label: 'Edit Tags' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.every(record => !record.isFolder),
            { key: 'Download', icon: <DownloadOutlined />, label: 'Download' },
        ],
        [
            () => true,
            { key: 'Refresh', icon: <ReloadOutlined />, label: 'Refresh' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.every(record => !record.isSystem),
            { key: 'Delete', icon: <DeleteOutlined />, label: 'Delete' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && !selectedRowKeys[0].isFolder,
            { key: 'CopyLink', icon: <ShareAltOutlined />, label: 'Copy Link' },
        ],
        [
            (selectedRowKeys) => selectedRowKeys.length === 1 && !selectedRowKeys[0].isSystem,
            { key: 'MoveTo', icon: <ScissorOutlined />, label: 'Move To' },
        ],
    ]
);

const ContextMenu = ({ x, y, isContextMenuOpen, setIsContextMenuOpen, selectedRowKeys, onMenuItemClick }) => {

    const ref = useRef(null)

    useClickAway(() => {
        setIsContextMenuOpen(false)
    }, () => document.getElementById("file-context-menu"))


    const menuItems = [...map].map(([condition, item]) => condition(selectedRowKeys) ? item : null).filter(Boolean);
    const menuHeight = menuItems.length * 40;
    const style = {
        position: 'fixed',
        left: x,
        // 防止菜单超出窗口
        top: (y + menuHeight > window.innerHeight) ? window.innerHeight - menuHeight : y,
        zIndex: 1000,
        display: isContextMenuOpen ? 'block' : 'none',
    };
    return <StyleMenu selectedKeys={[]} id="file-context-menu" ref={ref} style={style} items={menuItems} onClick={onMenuItemClick} />
}

export default ContextMenu