import { useRequest } from 'ahooks'
import { api } from '@redux/api'
import moment from 'moment'
import { useSelector } from 'react-redux'

export const useSummaryData = (type, period, endingMonth) => {
    const { data, loading } = useRequest(async () => {
        let result = null
        if (type === 'electricity') {
            result = await api.get_electricity_summary(period, endingMonth)
        } else if (type === 'gas') {
            result = await api.get_gas_summary(period, endingMonth)
        } else {
            return Promise.reject(new Error('Invalid type'))
        }
        if (result?.status === 200) {
            return result.data
        } else {
            return Promise.reject(new Error('Failed to fetch data'))
        }
    }, {
        refreshDeps: [type, period, endingMonth],
        cacheKey: `cacheKey-summary-${type}`
    })

    return { data, loading }
}

export const useWeatherData = (type, period, endingMonth) => {
    const start_month = moment(endingMonth).subtract(period - 1, 'month').startOf('month').format('YYYY-MM-DD')
    const end_month = moment(endingMonth).endOf('month').format('YYYY-MM-DD')
    const { data, loading } = useRequest(async () => {

        let result = null
        if (type === 'electricity') {
            result = await api.filterElectricity(`start_month=${start_month}&end_month=${end_month}&page=1&page_size=20000`)
        } else if (type === 'gas') {
            result = await api.filterGas(`start_month=${start_month}&end_month=${end_month}&page=1&page_size=20000`)
        } else {
            return Promise.reject(new Error('Invalid type'))
        }
        if (result?.status === 200) {
            return result?.data?.results ?? []
        } else {
            return Promise.reject(new Error('Failed to fetch data'))
        }
    }, {
        refreshDeps: [type, period, endingMonth],
        cacheKey: `cacheKey-all-weather-${type}`
    })

    return { data, loading }
}
