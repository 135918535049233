import React, { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import { useSummaryData } from "../hooks/useSummaryData";

export const GaugeCharts = ({ period, endingMonth }) => {

    const { data: electricityData } = useSummaryData('electricity', period, endingMonth)
    const { data: gasData } = useSummaryData('gas', period, endingMonth)

    const totalEui = useMemo(() => {
        return Math.round((electricityData?.annual_eui || 0) + (gasData?.annual_eui || 0))
    }, [electricityData, gasData])

    const gaugeOption = {
        series: [
            {
                type: 'gauge',
                min: 0,
                max: 500,
                radius: '85%',
                center: ['50%', '60%'],
                detail: {
                    formatter: '{value}',
                    textStyle: { fontSize: 18 }
                },
                data: [{ value: totalEui }],
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 40,
                        color: [
                            [
                                1, new echarts.graphic.LinearGradient(
                                    0, 0, 1, 0, [
                                    { offset: 0, color: '#30BF78' },
                                    { offset: 0.5, color: '#FAAD14' },
                                    { offset: 1, color: '#F4664A' },
                                ]
                                )
                            ],
                        ]
                    }
                },
                pointer: {
                    length: '40%',
                    width: 6
                },
                title: {
                    show: true,
                    offsetCenter: [0, '70%'], // x, y
                    textStyle: {
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                splitLine: {
                    show: true,
                    length: 1, // 刻度线长度
                    lineStyle: {
                        color: 'black', // 刻度线颜色
                        width: 1 // 刻度线宽度
                    }
                },
                axisTick: {
                    show: true,
                    splitNumber: 1 // 设置为5以显示0, 100, 200, 300, 400, 500
                },
                axisLabel: {
                    show: true,
                    formatter: (value) => {
                        return value % 100 === 0 ? value : ''; // 显示0, 100, 200, 300, 400, 和500
                    },
                    distance: -5 // 将数字移到内部
                },
            }
        ],
        title: {
            text: 'EUI (kBtu/sqft-yr)',
            left: 'center',
            top: '6%',
            textStyle: {
                color: '#396598',
                fontSize: 14
              }
        },
    };

    return <EChartsReact option={gaugeOption} style={{ width: '100%', height: '100%' }} />
}
