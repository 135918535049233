import React, { useMemo, useCallback, useState } from 'react';
import { Select, Form, message } from 'antd';
import { useRequest } from 'ahooks';
import { api } from "@redux/api";
import { jsforceResponse } from "@config/httpService";
import _ from "lodash";
import { useSelector } from 'react-redux';
import { convertToTreeStructure, getCompanyId } from '../utils';

export const UserCompanyFormItem = ({ value, record }) => {
    const form = Form.useFormInstance();
    const role = Form.useWatch('role');
    const [searchKeyword, setSearchKeyword] = useState('')

    const allFacilities = useSelector(state => state.Facility.data);

    const companyTreeOptions = useMemo(() =>
        convertToTreeStructure(allFacilities),
        [allFacilities]
    );

    const { data: options = [], loading } = useRequest(fetchCompanies, {
        debounceWait: 300,
        defaultParams: [''],
        onError: (error) => {
            message.error('Failed to fetch companies');
        },
    });

    const setFacilities = useCallback((role, company) => {
        if (!form) return;

        let facilities = [];
        let companies = [];

        if (role === 1) {
            facilities = allFacilities.map(facility => facility.record.id);
            companies = companyTreeOptions.map(company => getCompanyId(company.value));
        } else if (role === 3) {
            facilities = allFacilities
                .filter(facility => facility.record.company_id === company)
                .map(facility => facility.record.id);
            companies = [company];
        }

        const setFieldsValue = form.setFieldsValue ?? form.current?.setFieldsValue;
        if (setFieldsValue) {
            setFieldsValue({ role, user: facilities, companies });
        }
    }, [allFacilities, companyTreeOptions]);

    const handleSelect = useCallback((value) => {
        // form.setFieldsValue({ company: value });
        // const role = form.getFieldValue('role');
        if (role) {
            setFacilities(role, value);
        }
    }, [role, setFacilities]);

    const filterOptions = useMemo(() => {
        if (!searchKeyword) return options;
        return options.filter(option => {
            return option.label.toLowerCase().includes(searchKeyword.toLowerCase())
        })
    }, [options, searchKeyword])

    return (
        <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true, message: 'Please select a company!' }]}
        >
            <Select
                showSearch
                style={{ width: '100%' }}
                options={filterOptions}
                onSelect={handleSelect}
                searchValue={searchKeyword}
                onSearch={setSearchKeyword}
                filterOption={false}
                loading={loading}
                defaultValue={value || record}
                placeholder="Search a company"
                required
                allowClear
                notFoundContent={loading ? 'Loading...' : 'No results found'}
            />
        </Form.Item>
    );
};

async function fetchCompanies(query = '') {
    try {
        const result = await api.filterCompany('name=' + query);
        const res = jsforceResponse(result, '', false, true);
        const company_items = _.get(res, 'data.results', []);

        return company_items.map(item => ({
            value: item.id,
            label: item.name,
            record: { id: item.id, value: item.name },
        }));
    } catch (error) {
        message.error('Failed to fetch companies');
        throw error;
    }
};

