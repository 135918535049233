import React, { useState } from 'react';
import UserDetailFormFields from '../components/UserDetailFormFields';
import { Card, Button, Typography, Form, message } from 'antd';
import { RquestSignUp } from '@/redux/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useKeepAliveContext } from '@/hooks/use-keep-alive-context';
import { emitEvent, EventName } from '@/hooks/useEventBus';

const { Title } = Typography;

export default function CreateUser() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const creatorCompany = useSelector(state => state.Facility?.selected?.record?.company_id);
    const creatorCompanyName = useSelector(state => state.Facility?.selected?.record?.company);
    const { closeTab } = useKeepAliveContext();
    const [loading, setLoading] = useState(false);

    const goToDetailPage = (id) => {
        if (id) {
            navigate(`/UserDetail/${id}`);
        }
    }

    const createUser = () => {

        form.validateFields().then(({ id, avatar, company, user = [], companies = [], verify_password, ...values }) => {
            const resultData = {
                company,
                ...values,
            }

            if (avatar) {
                resultData.image = avatar.originFileObj;
            }

            if (company?.value) {
                resultData.company = company.value;
            }

            resultData.user = user.join(',');
            resultData.companies = companies.join(',');
            setLoading(true);
            RquestSignUp(resultData).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    closeTab();
                    emitEvent(EventName.USER_DETAIL_REFRESH);
                    goToDetailPage(response.data.id);
                    message.success('Create user successfully');
                } else {
                    message.error(typeof response.message === 'string' ? response.message : 'Create user failed');
                }


            }).catch(error => {
                message.error('Create user failed');
            }).finally(() => {
                setLoading(false);
            });
        }).catch(error => {
            message.error('Form validation failed. Please ensure all inputs are correct and complete.');
        });
    }



    return (<Card
        title={<Title level={4}>Create User</Title>}
        extra={
            <Button loading={loading} type="primary" onClick={createUser}>Create</Button>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                role: 4,
                company: {
                    label: creatorCompanyName,
                    value: creatorCompany,
                },
                is_active: true,
            }}
        >
            <UserDetailFormFields disabled={false} isCreate={true} />
        </Form>
    </Card>
    )
}