import styled from "styled-components";

export const FileManagementWrap = styled.div`

  display: flex;
  width: 100%;
  .left {
    /* flex: 1; */
    display: flex;
    .left-tree {
      flex: 1;
    }
  }
  .right {
    /* flex: 4; */
    .action-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .search-bar {
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }
        .action-bars {
          display: flex;
        }
        .left-bar {
          display: flex;
        }
      }

    }
    .content {
      display: flex;
      .file-list {
        flex: 3;
        .icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .file-list-item {
          padding: 2px 10px;
          display: flex;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background-image: linear-gradient(
              rgba(64, 158, 255, 0.7),
              rgba(64, 126, 255, 0.9)
            );
            color:white;
          }
          .icon-wrap {
            margin-right: 6px;
            /* filter: drop-shadow(0 0 0 white); */
          }
        }
      }
      .preview-wrap {
        flex: 1;
        display: flex;
        .preview {
          flex: 1;
        }
      }
    }

  .ant-tree .ant-tree-treenode {
    margin: 0 10px;
    padding: 5px 0;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    /* background-image: linear-gradient(
              rgba(64, 158, 255, 0.7),
              rgba(64, 126, 255, 0.9)
            ); */
    color:white;
    border-radius: 5px;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before, .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: #113456;
  }

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    /* display: none !important; */
  }

  .ant-table-row {
    .NameDropdownMenu {
      display: none;
    }
    &:hover {
      .NameDropdownMenu {

      display: unset;
    }
    }
  }



`

export const ActionBarWrap = styled.div`
  height: 40px;
  padding: 10px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`