import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select, TreeSelect, Form, Table } from 'antd';
import { convertToTreeStructure, getCompanyId } from '../utils';
import { useUpdate } from 'ahooks';
import './AssigningFacilityFormItem.css';
import { CaretDownOutlined } from '@ant-design/icons';

const columns = [
    {
        title: 'Preview Selected Facilities',
        dataIndex: 'label',
        render: (text) => <span>
            {text}</span>
    },
];

export const AssigningFacilityFormItem = ({ disabled }) => {

    const form = Form.useFormInstance();
    const allFacilities = useSelector(state => state.Facility.data);
    const companyTreeOptions = useMemo(() => convertToTreeStructure(allFacilities), [allFacilities]);

    const role = Form.useWatch('role');
    const companyValue = Form.useWatch('company');
    const company = companyValue?.value ?? companyValue;
    const user = Form.useWatch('user') ?? [];

    const update = useUpdate();

    const setFacilities = (facilities, companies) => {
        update();
        form.setFieldsValue({ user: facilities, companies });
    }

    const renderPicker = () => {

        const commonProps = {
            disabled,
            style: { width: 300 },
        }

        if (role === 1) {
            return null
        } else if (role === 2.1) {
            const defaultValues = companyTreeOptions.filter(c => c.children.some(child => user.includes(child.value))).map(c => c.value);
            return <Select
                {...commonProps}
                key={2.1 + company}
                options={companyTreeOptions}
                value={defaultValues}
                onChange={(_, companies) => {
                    const companyIds = companies.map(c => getCompanyId(c.value));
                    const facilityIds = companies.reduce((acc, c) => {
                        acc.push(...c.children.map(child => child.value));
                        return acc;
                    }, []);
                    setFacilities(facilityIds, companyIds)
                }}
                mode="multiple"
            />
        } else if (role === 2.2) {
            return <TreeSelect
                {...commonProps}
                switcherIcon={({ expanded }) => {
                    return <CaretDownOutlined style={{ fontSize: '18px' }} />
                }}
                filterTreeNode={(input, treeNode) => {
                    return treeNode.label.toLowerCase().includes(input.toLowerCase())
                }}
                key={2.2 + company}
                mode="multiple"
                treeCheckable={true}
                treeData={companyTreeOptions}
                value={user.filter(u => allFacilities.find(e => e.Id === u)).map(item => ({ label: allFacilities.find(e => e.Id === item)?.label, value: item }))}
                onChange={(facilityIds) => {
                    const companyIds = [...new Set(allFacilities.filter(f => facilityIds.includes(f.record.id)).map(f => f.record.company_id))];
                    setFacilities(facilityIds, companyIds)
                }}
                popupClassName='company-picker-tree-select-picker-popup' // 设置下拉菜单的最大高度
            />
        } else if (role === 3) {
            return null
        } else if (role === 4) {
            const facilityOptions = allFacilities.filter(e => e.record.company_id === company).map(e => ({ label: e.label, value: e.Id }));
            return <Select
                {...commonProps}
                key={4 + company}
                options={facilityOptions}
                value={user.filter(u => allFacilities.find(e => e.Id === u)).map(item => ({ label: allFacilities.find(e => e.Id === item)?.label, value: item }))}
                onChange={(facilityIds) => {
                    const companyIds = [...new Set(allFacilities.filter(f => facilityIds.includes(f.record.id)).map(f => f.record.company_id))];
                    setFacilities(facilityIds, companyIds)
                }}
                mode="multiple"
            />
        }
    }

    const filteredDataSource = () => {
        if (role === 1) {
            return companyTreeOptions
        } else {
            return convertToTreeStructure(allFacilities, user)
        }
    }

    return (
        <div>
            <div>
                {renderPicker()}
            </div>
            {
                role !== 1 && <Table
                    key={role}
                    rowKey='value'
                    style={{ margin: '10px 0' }}
                    expandable={{
                        defaultExpandAllRows: role === 2.1 || role === 2.2 ? false : true,
                    }}
                    pagination={false}
                    dataSource={filteredDataSource()} columns={columns}></Table>
            }
        </div>

    );
}

