import { useRequest } from 'ahooks';
import { notification } from 'antd';
import { api } from '@/redux/api';

export const useResetPassword = () => {
    const { loading, runAsync: resetPassword } = useRequest(
        (email) => api.forgetpassword(email),
        {
            manual: true,
            onSuccess: () => {
                notification.success({
                    message: 'Success',
                    description: 'Password reset successfully, please check your email',
                    placement: 'topLeft'
                });
            },
            onError: (error) => {
                notification.error({
                    message: 'Error',
                    description: error.message || 'Failed to reset password',
                    placement: 'topLeft'
                });
            }
        }
    );

    const handleResetPassword = (email) => {
        if (!email) {
            return notification.error({
                message: 'Error',
                description: 'Please enter email',
                placement: 'topLeft'
            });
        }
        resetPassword(email);
    };

    return {
        loading,
        resetPassword: handleResetPassword
    };
}; 