import React from 'react';
import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import {NoDecimalFormat} from "@components/Format";

const EuiGuage = (props) => {
    const gaugeOption = {
        series: [
            {
                type: 'gauge',
                min: 0,
                max: 500,
                radius: '100%',
                center: ['50%', '65%'],
                detail: {
                    formatter: '{value}',
                    textStyle: { fontSize: 12 },
                    offsetCenter: [0, '60%'],
                },
                data: [{ value: props.gauge }],
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 20,
                        color: [
                            [
                                1, new echarts.graphic.LinearGradient(
                                    0, 0, 1, 0, [
                                    { offset: 0, color: '#30BF78' },
                                    { offset: 0.5, color: '#FAAD14' },
                                    { offset: 1, color: '#F4664A' },
                                ]
                                )
                            ],
                        ]
                    }
                },
                pointer: {
                    length: '55%',
                    width: 4,
                    itemStyle: {
                        color: '#D0D0D0'
                    }
                },
                title: {
                    show: true,
                    offsetCenter: [0, '85%'],
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'bold'
                    }
                },
                splitLine: {
                    show: true,
                    length: 6,
                    lineStyle: {
                        color: 'black',
                        width: 1
                    }
                },
                axisTick: {
                    show: true,
                    splitNumber: 1,
                    length: 4,
                },
                axisLabel: {
                    show: true,
                    fontSize: 10,
                    formatter: (value) => {
                        return value % 100 === 0 ? value : '';
                    },
                    distance: 30
                },
                detail: {
                    formatter: (value) => NoDecimalFormat(value),
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#000'
                }
            }
        ]
    };

    return (
        <>
            <EChartsReact 
                option={gaugeOption} 
                style={{ 
                    height: props.height || '500px',
                    width: '100%',
                    margin: '0 auto'
                }}
            />
            <div className={'pb-5'}></div>
        </>
    );
};

export default EuiGuage;
