import React, { Component, PureComponent } from "react";
import EditableTable from "@components/Table/EditableTable";
import {
  WorkorderColumns,
  WorkorderProps,
} from "@containers/facility/Workorder/WorkorderColumns";
import _ from "lodash";
import actions from "@redux/workorder/actions";
import { connect } from "react-redux";
import { api } from "@redux/api";
import { WorkorderFormFields } from "@containers/facility/Workorder/WorkorderFormFields";
import { WorkorderComment } from "@containers/facility/Workorder/WorkorderComment";
import {
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import { WidgetWrapper } from "@components/WidgetBox/Widgets.styles";
import SearchLookup from "@components/SearchLookup";
import moment from "moment/moment";
import PermissionControl from "@components/Permission/Permission";
import ColumnChart from "@containers/UtilityData/Charts/ColumnChart";
import queryString from "query-string";
import { withRouter } from "@/router/withRouter";
import { hasValues } from "@components/help";

const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;

class Workorder extends PureComponent {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.filterFormRef = React.createRef();
    this.state = {
      columnsStateMap: {},
      data: [],
      status: null,
      energy: null,
      equipment: null,
      project: null,
      project_name: null,
      equipment_name: null,
      priority: null,
      isLoading: false,
      issue_keyword: null,
      query: null,
      completeYear: null,
      completeMonth: null,
      recordYear: null,
      recordMonth: null,
      groupedStatusData: [],
      groupedPriorityData: [],
      groupedMonthlyData: [],
      panel: null,
      complete_date: [null, null],
      created_date: [null, null],
      report_date: [null, null],
      project_keyword: null,
      equipment_keyword: null,
      issue: null,
      tableRef: null,
      filterHasValue: false,
      filterCompletedWorkorder: false,
      filterMyWorkorder: false,
      assignTo: null,
      wo_id: null,
      responsible_party: null,
      responsible_party_keyword: null,
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params?.status) {
      this.setState({
        status: params.status,
      });
    }
    if (params?.priority) {
      this.setState({
        priority: params.priority,
      });
    }
    if (params?.complete_date) {
      this.setState({
        complete_date: [
          moment().subtract(params?.complete_date, "days"),
          moment(),
        ],
      });
    }
    if (params?.report_date) {
      this.setState({
        report_date: [moment().subtract(params?.report_date, "days"), moment()],
      });
    }
    if (params.created_date) {
      this.setState({
        created_date: [
          moment().subtract(params?.created_date, "days"),
          moment(),
        ],
      });
    }
    if (params?.id) {
      this.setState({
        wo_id: params.id,
      });
    }
  }

  getAllWorkOrders = (query) => {
    return api
      .allworkorderview(query)
      .then((response) => {
        let dataSource = response.data;
        this.setState({
          groupedMonthlyData: dataSource?.sorted_monthly_data,
        });
      })
      .catch((error) => error);
  };

  handleCreateWorkorder = (body) => {
    // console.log('created', this.props.parentProject)

    body["project"] = this.props.SelectedProject;

    let parent = this.props.relatedList;
    if (parent) {
      body["priority"] = "Urgent";
      body["project"] = this.props.parentProject;
      body["equipment"] = this.props.parentEquipment;
    }

    return api
      .createWorkorder(body)
      .then((response) => {
        // console.log('create', response);
        return response.data;
      })
      .catch((error) => error);
  };

  handleDeleteWorkorder = (id) => {
    return api
      .deleteWorkorder(id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  };

  handleBulkInsert = (body) => {
    return api
      .bulkWorkorderInsert(body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };

  handleBulkDelete = (body) => {
    return api
      .bulkDeleteWorkorder(body)
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  };

  handOnlineSave = (key, row) => {
    row["project"] = this.props.SelectedProject;

    let parent = this.props.relatedList;
    if (parent) {
      row["priority"] = "Urgent";
      row["project"] = this.props.parentProject;
    }
    row["modifiedby_name"] = this.props.userName;
    return api
      .editWorkorder(key, row)
      .then((response) => {
        return response;
      })
      .catch((error) => error);
  };

  handleFilterHasValule = (e) => {
    const initialState = {
      issue: this.state.issue,
      equipment_name: this.state.equipment_name,
      project_name: this.state.project_name,
      status: this.state.status,
      priority: this.state.priority,
      energy: this.state.energy,
      complete_date: this.state.complete_date,
      report_date: this.state.report_date,
      created_date: this.state.created_date,
      responsible_party: this.state.responsible_party,
    };
    const filterHasValue = hasValues(initialState);
    return filterHasValue;
  };

  handleFilter = (query) => {
    const filterHasValue = this.handleFilterHasValule();
    console.log(filterHasValue, "filterHasValue");
    this.setState({
      isLoading: true,
      filterHasValue,
    });

    let query_new =
      query +
      (this.state.wo_id ? "&id=" + this.state.wo_id : "") +
      (this.state.issue ? "&issue=" + this.state.issue : "") +
      (this.state.assignTo ? "&assignTo=" + this.state.assignTo : "") +
      (this.state.equipment_name
        ? "&equipment_name=" + this.state.equipment_name
        : "") +
      (this.state.project_name
        ? "&project_name=" + this.state.project_name
        : "") +
      (this.state.status ? "&status=" + this.state.status : "") +
      (this.state.priority ? "&priority=" + this.state.priority : "") +
      (this.state.energy ? "&energy_impact=" + this.state.energy : "") +
      (this.state.responsible_party ? "&responsible_party=" + this.state.responsible_party : "") +
      (this.state.complete_date[0]
        ? "&complete_date_start=" +
          moment(this.state.complete_date[0]).format("YYYY-MM-DD")
        : "") +
      (this.state.complete_date[1]
        ? "&complete_date_end=" +
          moment(this.state.complete_date[1]).format("YYYY-MM-DD")
        : "") +
      (this.state.report_date[0]
        ? "&report_date_start=" +
          moment(this.state.report_date[0]).format("YYYY-MM-DD")
        : "") +
      (this.state.report_date[1]
        ? "&report_date_end=" +
          moment(this.state.report_date[1]).format("YYYY-MM-DD")
        : "") +
      (this.state.created_date[0]
        ? "&created_date_start=" +
          moment(this.state.created_date[0]).format("YYYY-MM-DD")
        : "") +
      (this.state.created_date[1]
        ? "&created_date_end=" +
          moment(this.state.created_date[1]).format("YYYY-MM-DD")
        : "");
    let parent = this.props.relatedList;
    if (parent) {
      if (this.props.parentProject) {
        query_new += "project=" + this.props.parentProject + "&";
      }
      if (this.props.parentEquipment) {
        query_new += "equipment=" + this.props.parentEquipment + "&";
      }
    }
    return api
      .filterWorkorder(query_new)
      .then((response) => {
        let dataSource = response.data.results;
        return {
          data: dataSource,
          success: true,
          total: response.data.count,
        };
      })
      .catch((error) => error);
  };

  getWorkorder = (data) => {
    this.setState({
      data,
      isLoading: false,
    });
    // this.props.setWorkorderSuccess(data)
  };

  handleFormRelated = (e) => {
    console.log("handleFormRelated", e);
  };

  handleClearButtonClick = (e) => {
    this.setState(
      {
        status: null,
        priority: null,
        energy_impact: null,
        equipment: null,
        project: null,
        query: null,
      },
      function () {
        this.refresh(false);
      }
    );
  };
  handlePostComment = (e) => {
    if (e) {
      return <WorkorderComment id={e} />;
    }
  };

  refresh = (needRequest) => {
    return this.tableRef?.current?.refresh(needRequest);
  };

  handleLookupFilter = (e) => {
    let query_new =
      (this.state.issue_keyword ? "issue=" + this.state.issue_keyword : "") +
      (this.state.equipment_keyword
        ? "equipment_name=" + this.state.equipment_keyword
        : "") +
      (this.state.project_keyword
        ? "project_name=" + this.state.project_keyword
        : "") +
        (this.state.responsible_party_keyword
        ? "responsible_party=" + this.state.responsible_party_keyword
        : "") +
      "&limit=15";

    return api
      .filterWorkorder(query_new)
      .then((response) => {
        let dataSource = response.data.results;
        return {
          data: dataSource,
          success: true,
          total: response.data.count,
        };
      })
      .catch((error) => {
        return error;
      });
  };

  handleEquipmentLookup = (e) => {
    return api
      .lookupEquipments(e)
      .then((response) => {
        let dataSource = response.data;
        return {
          data: dataSource,
          success: true,
          total: response.data.count,
        };
      })
      .catch((error) => {
        return error;
      });
  };

  handleSelect = (e, type) => {
    this.setState(
      {
        [type]: e,
        limit: null,
      },
      function () {
        this.refresh(false);
      }
    );
  };

  handleSearchSelect = (e, type) => {
    this.setState(
      {
        [type]: e,
        limit: null,
      },
      function () {
        this.refresh(false);
      }
    );
  };

  handleSearch = (e, type) => {
    this.setState({
      [type + "_keyword"]: e,
    });
    return this.handleLookupFilter();
  };

  onDateChange = (dates, state, index) => {
    let bDate = this.state[state];
    bDate[index] = dates;

    this.setState(
      {
        [state]: bDate,
      },
      function () {
        this.refresh(false);
      }
    );
  };
  resetFilters = () => {

    this.setState(
      {
        issue: "",
        equipment_name: "",
        project_name: "",
        status: null,
        priority: "",
        energy: "",
        complete_date: [null, null],
        report_date: [null, null],
        created_date: [null, null],
        filterCompletedWorkorder: false,
        assignTo: null,
        filterMyWorkorder: false,
        responsible_party: null,
      },
      function () {
        if (this.filterFormRef && this.filterFormRef.current) {
          this.filterFormRef.current.resetFields();
        }
        this.refresh(false);
      }
    );
  };

  renderFilter = () => {
    return (
      <div className={"flex gap-5 items-center"}>
        <SearchLookup
          placeholder={"issue"}
          name={"issue"}
          SelectedLookup_CommonRequest={(e) =>
            this.handleSearchSelect(e, "issue")
          }
          Lookup_CommonupRequest={(e) => this.handleSearch(e, "issue")}
        />
        <Checkbox
          checked={this.state.filterCompletedWorkorder}
          onChange={this.handleFilterCompleteWorkorder}
        >
          Hide Completed
        </Checkbox>
        <Checkbox
          checked={this.state.filterMyWorkorder}
          onChange={this.handleFilterMyWorkorder}
        >
          Assign to me
        </Checkbox>
      </div>
    );
  };
  handleFilterCompleteWorkorder = (e) => {
    if (e.target.checked) {
      if (this.filterFormRef.current) {
        this.filterFormRef.current.setFieldValue("status", ["Not Started","InProgress", "On-Hold", ,"Verifying"]);
      }
      this.setState(
        {
          filterCompletedWorkorder: true,
          status: "Not Started,InProgress,On-Hold,Verifying",
        },
        () => {

          this.refresh(false);
        }
      );
    } else {
      if (this.filterFormRef.current) {
        this.filterFormRef.current.setFieldValue("status", null);
      }
      this.setState(
        {
          filterCompletedWorkorder: false,
          status: null,
        },
        () => {

          this.refresh(false);
        }
      );
    }
  };

  handleFilterMyWorkorder = (e) => {
    if (e.target.checked) {
      this.setState(
        {
          filterMyWorkorder: true,
          assignTo: this.props.CurrentUserId,
        },
        () => {
          this.refresh(false);
        }
      );
    } else {
      this.setState(
        {
          filterMyWorkorder: false,
          assignTo: null,
        },
        () => {
          this.refresh(false);
        }
      );
    }
  };

  renderSearches = () => {
    return (
      <Form
        ref={this.filterFormRef}
        className={"filter-form"}
        initialValues={{
          status: Array.isArray(this.state.status)
            ? this.state.status
            : typeof this.state.status === "string"
            ? this.state.status?.split(",")
            : null,
        }}
      >
        <Form.Item label={"Equipment"} name="equipment_search_name">
          <SearchLookup
            placeholder={"Equipment"}
            name={"name"}
            SelectedLookup_CommonRequest={(e) =>
              this.handleSearchSelect(e, "equipment_name")
            }
            Lookup_CommonupRequest={(e) => this.handleEquipmentLookup(e)}
          />
        </Form.Item>

        {/* <Form.Item label={'Project'} name="project_search_name">
                    <SearchLookup
                        placeholder={'Project'}
                        name={'project_search_name'}

                        SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'project_name')}
                        Lookup_CommonupRequest={(e) => this.handleSearch(e, 'project')}
                    />
                </Form.Item> */}

        <Form.Item label={"WO #"} name="workordernumber">
          <SearchLookup
            placeholder={"WO #"}
            name={"workordernumber"}
            SelectedLookup_CommonRequest={(e) =>
              this.handleSearchSelect(e, "workordernumber")
            }
            Lookup_CommonupRequest={(e) =>
              this.handleSearch(e, "workordernumber")
            }
          />
        </Form.Item>

        <Form.Item label={"Status"} name="status">
          <Select
            mode="multiple"
            allowClear
            placeholder={"Status"}
            className={"min-multi-selector"}
            value={this.state.status}
            onChange={(e) => this.handleSearchSelect(e, "status")}
          >
            <Option value="Not Started">Not Started</Option>
            <Option value="InProgress">In Progress</Option>
            <Option value="On-Hold">On-hold</Option>
            <Option value="Verifying">Verifying</Option>
            <Option value="Completed">Completed</Option>
          </Select>
        </Form.Item>

        <Form.Item label={"Priority"} name="priority">
          <Select
            allowClear
            placeholder={"Priority"}
            value={this.state.priority}
            onChange={(e) => this.handleSearchSelect(e, "priority")}
          >
            <Option value="Urgent">Urgent</Option>
            <Option value="Important">Important</Option>
            <Option value="Low">Low</Option>
          </Select>
        </Form.Item>

        <Form.Item label={"Responsible Party"} name="responsible_party">
          <SearchLookup
              placeholder={"Responsible Party"}
              name={"responsible_party"}
              SelectedLookup_CommonRequest={(e) =>
                  this.handleSearchSelect(e, "responsible_party")
              }
              Lookup_CommonupRequest={(e) =>
                  this.handleSearch(e, "responsible_party")
              }
          />
        </Form.Item>

        <Form.Item label={"Energy"} name="energy">
          <Select
            allowClear
            placeholder={"Energy"}
            onChange={(e) => this.handleSearchSelect(e, "energy")}
          >
            <Option value="High">High</Option>
            <Option value="Medium">Medium</Option>
            <Option value="Low">Low</Option>
            <Option value="None">None</Option>
          </Select>
        </Form.Item>

        <Form.Item label={"Complete Start"} name="complete_date_start">
          <DatePicker
            placeholder={"complete start"}
            value={this.state.complete_date[0]}
            onChange={(dates) =>
              this.onDateChange(dates, "complete_date", 0, "")
            }
          />
        </Form.Item>

        <Form.Item label={"Complete End"} name="complete_date_end">
          <DatePicker
            placeholder={"complete end"}
            value={this.state.complete_date[1]}
            onChange={(dates) =>
              this.onDateChange(dates, "complete_date", 1, "")
            }
          />
        </Form.Item>

        <Form.Item label={"Report Start"} name="report_date_start">
          <DatePicker
            placeholder={"report start"}
            value={this.state.report_date[0]}
            onChange={(dates) => this.onDateChange(dates, "report_date", 0, "")}
          />
        </Form.Item>

        <Form.Item label={"Report End"} name="report_date_end">
          <DatePicker
            placeholder={"report end"}
            value={this.state.report_date[1]}
            onChange={(dates) => this.onDateChange(dates, "report_date", 1, "")}
          />
        </Form.Item>

        <Form.Item label={"Created Start"} name="created_date_start">
          <DatePicker
            placeholder={"created start"}
            value={this.state.created_date[0]}
            onChange={(dates) =>
              this.onDateChange(dates, "created_date", 0, "")
            }
          />
        </Form.Item>

        <Form.Item label={"Created End"} name="created_date_end">
          <DatePicker
            placeholder={"created end"}
            value={this.state.created_date[1]}
            onChange={(dates) =>
              this.onDateChange(dates, "created_date", 1, "")
            }
          />
        </Form.Item>
      </Form>
    );
  };

  handlePanelChange = (e) => {
    console.log("handlePanelChange", e);
    this.setState(
      {
        panel: e[0],
      },
      function () {
        if (e[0]) {
          // this.refresh(false)
          this.getAllWorkOrders();
        }
      }
    );
  };

  gettableRef = (tableRef) => {
    this.setState({
      tableRef,
    });
  };

  render() {
    let parent = this.props.relatedList;

    return (
      <div className="px-5">
        {parent ? null : (
          <Collapse
            ghost
            onChange={this.handlePanelChange}
            destroyInactivePanel={false}
            className="facility-calc-table-resize-collapse"
          >
            <Panel
              key={"col0"}
              header={
                <Divider>
                  <h1>
                    {!this.state.panel
                      ? "Show work order history"
                      : "Hide work order history"}
                  </h1>
                </Divider>
              }
            >
              <Row className={"w-full"}>
                {this.state.panel === "col0" ? (
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className={"my-2"}
                  >
                    <WidgetWrapper className={"outerCardWrapper "}>
                      <PermissionControl allowedPermissions={"1:workorder"}>
                        <ColumnChart
                          height={250}
                          data={this.state.groupedMonthlyData}
                          type={"date"}
                          value={"value"}
                          seriesField={"status"}
                          slider={true}
                          isStack={true}
                          isGroup={false}
                          colors={{
                            Completed: "green",
                            Verifying: "blue",
                            "On-Hold": "lightgrey",
                            InProgress: "orange",
                            "Not Started": "red",
                          }}
                          title="Workorder History"
                        />
                      </PermissionControl>
                    </WidgetWrapper>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Panel>
          </Collapse>
        )}
        <EditableTable
          ref={this.tableRef}
          columns={WorkorderColumns()}
          data={this.state.data}
          columnProps={WorkorderProps}
          formFields={WorkorderFormFields(this.state.tableRef)}
          formRelated={this.handleFormRelated}
          postComment={this.handlePostComment}
          getRecord={this.getWorkorder}
          createRecords={this.handleCreateWorkorder}
          deleteRecord={this.handleDeleteWorkorder}
          bulkInsert={this.handleBulkInsert}
          bulkDelete={this.handleBulkDelete}
          handOnlineSave={this.handOnlineSave}
          handleFilter={this.handleFilter}
          renderFilter={this.renderFilter}
          resetFilters={this.resetFilters}
          filterHasValue={this.state.filterHasValue}
          relatedList={this.props.relatedList}
          relatedId={this.props.relatedId}
          relatedObject={this.props.relatedObject}
          hide_search={true}
          isRowClickable={false}
          isChat={true}
          isRelated={false}
          isAttachments={true}
          setFilters={this.setFilters}
          getFormRef={this.gettableRef}
          renderSearches={this.renderSearches}
          defaultDisplayColumns={{
            reportDate: {
              show: false,
            },
            created_date: {
              show: false,
            },
            complete_date: {
              show: false,
            },
            reportBy: {
              show: false,
            },
            project: {
              show: false,
            },
            createdby_name: {
              show: false,
            },
            lastModified_name: {
              show: false,
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Workorder: _.get(state.Workorder, "data"),
    SelectedEquipment: _.get(state.Equipment, "selectedEquipment.id"),
    SelectedProject: _.get(state.Project, "selectedProject.id"),
    CurrentUserId: _.get(state.Auth, "userId"),
    userName: state?.Auth?.user || state?.Auth?.first_name + ' ' + state?.Auth?.last_name
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setWorkorderRequest: (e) => dispatch(actions.WorkorderRequest(e)),
    setWorkorderSuccess: (e) => dispatch(actions.WorkorderSuccess(e)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Workorder)
);
