import React from 'react';
import { Form, Input, Radio, Row, Col } from 'antd';
import { AvatarUpload } from './AvatarUpload';
import { UserCompanyFormItem } from './UserCompanyFormItem';
import { AssigningFacilityFormItem } from './AssigningFacilityFormItem';
import { UserRoleFormItem } from './UserRoleFormItem';
import { PermissionProfileFormItem } from './PermissionProfileFormItem';

const UserDetailFormFields = ({ disabled, isCreate }) => {

    const role = Form.useWatch('role');
    const needAssigningFacilityRequired = !(role === 1 || role === 3);
    return (
        <Row gutter={24}>
            <Col xs={24}>
                <Form.Item
                    label="Avatar"
                    name="image"
                >
                    <AvatarUpload key={disabled} disabled={disabled} />
                </Form.Item>
            </Col>

            <Col xs={24} md={12}>
                <Form.Item
                    label="Username"
                    name="username"
                    required
                    rules={[{ required: true, message: 'Please input username!' }]}
                >
                    <Input />
                </Form.Item>
            </Col>

            <Col xs={24} md={12}>
                <Form.Item
                    label="Email"
                    name="email"
                    required
                    rules={[{ required: true, message: 'Please input email!' }, {
                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/,
                        message: 'Please input a valid email address!'
                    }]}
                >
                    <Input />
                </Form.Item>
            </Col>

            {isCreate && <>
                <Col xs={24} md={12}>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                min: 6,
                                message: 'Password must be at least 6 characters',
                            }
                        ]}
                    >
                        <Input.Password
                            size="large"
                            type="password"
                            placeholder="Password"
                            autoComplete="false"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        label="Confirm Password"
                        name="verify_password"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            type="password"
                            placeholder="Password"
                            autoComplete="false"
                        />
                    </Form.Item>
                </Col>
            </>
            }



            <Col xs={24} md={12}>
                <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                >
                    <Input />
                </Form.Item>
            </Col>

            {/* <Col xs={24} md={12}>
                <Form.Item
                    label="Middle Name"
                    name="middle_name"
                >
                    <Input />
                </Form.Item>
            </Col> */}

            <Col xs={24} md={12}>
                <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <UserCompanyFormItem />
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Status"
                    name="is_active"
                    rules={[{ required: true, message: 'Please select status!' }]}
                >
                    <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Disable</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>



            <Col xs={24}>
                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Please select role!' }]}
                >
                    <UserRoleFormItem disabled={disabled} />
                </Form.Item>
            </Col>
            
            <Col xs={12}>
                <PermissionProfileFormItem />
            </Col>

            <Col xs={24}>
                <Form.Item
                    label="Assigning Company and Facility"
                    name="user"
                    required={needAssigningFacilityRequired}
                    dependencies={['role', 'company']}
                    rules={[{ validator: (_, value) => value?.length > 0 ? Promise.resolve() : Promise.reject('Please select at least one facility!') }]}
                >
                    <AssigningFacilityFormItem disabled={disabled} />
                </Form.Item>
            </Col>
            <Form.Item name="avatar" />
            <Form.Item name="id" />
            <Form.Item name="companies" />
            <Form.Item name="user" />
            <Form.Item name="image" />
        </Row>
    );
};

export default UserDetailFormFields;
