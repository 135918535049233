import actions from './actions';

const initState = {
    dateRange: {}
};

export default function GlobalReducer(state = initState, action) {
    switch (action.type) {
        case actions.Date_Range:
            return {
                ...state,
                dateRange: action.payload.data
            };
        default:
            return state;
    }
}
