import React, { useRef, useEffect } from 'react';
import { Area } from '@antv/g2plot';
import { DataView } from "@antv/data-set";
import _ from "lodash";
import LayoutContent from "@components/utility/layoutContent";
import moment from "moment";
import { ThreeDecimalFormat, TwoDecimalFormat } from "@components/Format";

const EUILineChart = ({ x, seriesField, y, data, height }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (data) {
            draw();
        }
        return () => {
        };
    }, [data]);
    console.log('data:', data, x, y, seriesField, height)
    const draw = () => {
        const chartDom = chartRef.current;
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        const area = new Area(chartDom, {
            data: data.map(item => {
                delete item.area;
                return item;
            }),
            xField: x,
            yField: y,
            seriesField: seriesField,
            height: height,
            color: ['blue', 'brown'],
            yAxis: {
                nice: true,
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
            },
            tooltip: {
                showMarkers: false,
                shared: true, // Ensure that it shows values for all series
                fields: ['month', 'type', 'eui'], // Specify which fields to show in the tooltip
                formatter: (datum) => {
                    return [{
                        name: datum.type,
                        value: `${TwoDecimalFormat(datum.eui)} kBtu/sqft-yr`, // Format your EUI values properly
                    },
                {
                    name: datum.type,
                    value: `${TwoDecimalFormat(datum.eui)} kBtu/sqft-yr`, // Format your EUI values properly
                }];
                    // return {
                    //     name: datum.type,
                    //     value: `${TwoDecimalFormat(datum.eui)} kBtu/sqft-yr`, // Format your EUI values properly
                    // };
                },
            },
            legend: {
                layout: 'horizontal',
                position: 'top-right',
            },
            point: {
                size: 2,
                style: {
                    lineWidth: 2,
                },
            },
            interactions: false,
        });

        area.render();
        chartInstanceRef.current = area;
    };

    return (
            <>
            <div ref={chartRef} />
            </>
    );
};

export default EUILineChart;
