import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import { useSummaryData } from "../hooks/useSummaryData";
import moment from "moment";

const StyleTable = styled(Table)`
    td {
        border: 0 !important;
    }
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #396598 !important;
        color: #fff !important;
        cursor: pointer !important;
    }
`

const columns = [
    {
        title: <div style={{ padding: '0 10px', fontWeight: 'bold' }}>Name</div>,
        dataIndex: 'name',
        render(text) {
            return <span style={{ padding: '0 10px' }}>{text}</span>
        },
    },
    {
        title: <div style={{ fontWeight: 'bold' }}>Value</div>,
        dataIndex: 'value',
        align: 'right',
    },
    {
        title: <div style={{ paddingLeft: '10px', fontWeight: 'bold' }}>Unit</div>,
        dataIndex: 'unit',
        render(text) {
            return <span style={{ paddingLeft: '10px' }}>{text}</span>
        }
    },
];

const formatNumber = (value) => {
    if (value === '-' || value == null) return '-';
    return value.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

const formatCost = (value) => {
    if (value === '-' || value == null) return '-';
    return value.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

const formatThreeDecimals = (value) => {
    if (value === '-' || value == null) return '-';
    return value.toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 });
};

export const KeyStatistics = ({ period, endingMonth }) => {

    const { data: electricityData = {
        electricity: [],
    } } = useSummaryData('electricity', period, endingMonth)
    const { data: gasData = {
        gas: []
    } } = useSummaryData('gas', period, endingMonth)

    const start_month = moment(endingMonth).subtract(period - 1, 'month').startOf('month').format('YYYY-MM')
    const end_month = moment(endingMonth).endOf('month').format('YYYY-MM')

    const dataSource = [
        {
            name: 'Total Electricity Usage',
            value: formatNumber(electricityData.total_usage || '-'),
            unit: 'kWh',
        },
        {
            name: 'Total Electricity Cost',
            value: '$ ' + formatCost(electricityData.total_cost || '-'),
            unit: '',
        },
        {
            name: 'Average Electricity Price',
            value: electricityData.total_usage ?
                '$ ' + formatThreeDecimals(Number((electricityData.total_cost / electricityData.total_usage).toFixed(3))) :
                '-',
            unit: 'per kWh',
        },
        {
            name: 'Total Gas Usage',
            value: formatCost(gasData.total_usage || '-'),
            unit: 'MMBtu',
        },
        {
            name: 'Total Gas Cost',
            value: '$ ' + formatCost(gasData.total_cost || '-'),
            unit: '',
        },
        {
            name: 'Average Gas Price',
            value: gasData.total_usage ?
                '$ ' + formatThreeDecimals(Number((gasData.total_cost / gasData.total_usage).toFixed(3))) :
                '-',
            unit: 'per MMBtu',
        },
        {
            name: 'Total Cost',
            value: '$ ' + formatCost(electricityData.total_cost + gasData.total_cost),
            unit: '',
        },
        {
            name: 'Total Cost per Sqft per year',
            value: '$ ' + formatThreeDecimals((gasData.total_area_cost + electricityData.total_area_cost) * 12 / period),
            unit: 'per sqft-yr',
        },
        {
            name: 'Energy Use Intensity (EUI)',
            value: formatNumber(Math.round((electricityData?.annual_eui || 0) + (gasData?.annual_eui || 0))),
            unit: 'kBtu/sqft-yr',
        },

    ];

    return <>
        <div style={{ fontSize: 14, fontWeight: 'bold', background: '#396598', color: '#fff', borderRadius: '10px 10px 0 0', padding: '5px 10px' }}>
            Key Statistics （{start_month} - {end_month}）
        </div>
        <div style={{ background: '#396598', paddingBottom: '10px', borderRadius: '0 0 10px 10px' }}>
            <StyleTable size='small' dataSource={dataSource} columns={columns} pagination={false} />
        </div>
    </>
}